import { HOW_IT_WORKS_LINK } from "@/lib/constants"
import { Link } from "react-router-dom"

export const AppLinks = () => {
  return (

    <div className='w-full flex flex-col gap-4 text-white'>

      <Link to="https://telegra.ph/QA-for-PumpHub-07-14" target="_blank">
        <div className='w-full flex justify-between gap-4 items-center'>
          <img src='/images/ic_faq.svg' alt='FAQ' className='w-8 h-8' />
          <span className='flex-1 text-xs'>FAQ</span>
          <img src='/images/ic_arrow_right.svg' alt='Go' className='w-4 h-4' />
        </div>
      </Link>

      <Link to={HOW_IT_WORKS_LINK} target="_blank">
        <div className='w-full flex justify-between gap-4 items-center'>
          <img src='/images/ic_help.svg' alt='Help' className='w-8 h-8' />
          <span className='flex-1 text-xs'>How it works</span>
          <img src='/images/ic_arrow_right.svg' alt='Go' className='w-4 h-4' />
        </div>
      </Link>

      <Link to="https://telegra.ph/Support-07-14-3" target="_blank">
        <div className='w-full flex justify-between gap-4 items-center'>
          <img src='/images/ic_support.svg' alt='Support' className='w-8 h-8' />
          <span className='flex-1 text-xs'>Support</span>
          <img src='/images/ic_arrow_right.svg' alt='Go' className='w-4 h-4' />
        </div>
      </Link>

    </div>
  )
}