import { Bucket, R2 } from "node-cloudflare-r2";
import { CLOUDFLARE_ACCESS_ID, CLOUDFLARE_ACCESS_KEY, CLOUDFLARE_ACCOUNT_ID, CLOUDFLARE_BUCKET, CLOUDFLARE_PUBLIC_URL } from "./constants";

export const getBucket = (): Bucket => {
    const client = new R2({
        accessKeyId: CLOUDFLARE_ACCESS_ID,
        secretAccessKey: CLOUDFLARE_ACCESS_KEY,
        accountId: CLOUDFLARE_ACCOUNT_ID,
    });

    const bucket = client.bucket(CLOUDFLARE_BUCKET);
    return bucket;
}

export const uploadFile = async (buffer: Uint8Array, key: string): Promise<string> => {
    const bucket = getBucket();
    const ret = await bucket.uploadStream(buffer, key);
    if (!ret.uri) {
        throw Error("Upload file failed");
    }

    return `${CLOUDFLARE_PUBLIC_URL}/${key}`;
}
