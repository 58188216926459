import React from 'react';
import { LinkButton } from '@/components/common/LinkButton';
import PlusIcon from '@/assets/plus.svg';
import './index.less';

export function StartNewCoin() {
  return <TailwindButton icon={<img src={PlusIcon} width={20} />}>
    <LinkButton to='/create' className='text-black' >
      Start a new coin
    </LinkButton>
  </TailwindButton>
}

const TailwindButton = (
  { icon, children, className = '' }:
    { icon?: React.ReactElement, children: React.ReactNode, className?: string }
) => {
  const buttonClasses = `
    create-new-button
    mt-3 pd-[8px 0]
    hover:bg-gray-800
    border-4
    border-[#225884]
    border-t-[#a0d7fb]
    border-l-[#a0d7fb]
    text-white 
    p-[2px] 
    px-[10px] 
    flex 
    items-center 
    w-full
    font-bold
    uppercase
    gap-2 px-2 py-1 leading-[24px] 
    flex items-center justify-center w-full ${className}
  `;
  return <button className={buttonClasses}>
    {icon && React.cloneElement(icon as React.ReactElement)}
    {children}
  </button>
};
