import type { ComponentType, JSX } from 'react';

import { CreatePage } from '@/pages/create';
import { ProfilePage } from '@/pages/profile';
import { HomePage } from '@/pages/home';
import { TradingPage } from '@/pages/trading';
import LuckyPage from '@/pages/lucky';

interface Route {
  path: string;
  element: ComponentType;
  title?: string;
  icon?: JSX.Element;
}

export const routes: Route[] = [
  { path: '/home', element: HomePage },
  { path: '/trading/:address', element: TradingPage },
  { path: '/create', element: CreatePage },
  { path: '/profile', element: ProfilePage },
  { path: '/lucky', element: LuckyPage },
];
