import { createUser, fetchUserInfo } from "@/api/user";
import { globalData } from "@/lib/constants";
import { JettonToken, UserInfo } from "@/lib/types";
import { useLaunchParams } from "@telegram-apps/sdk-react";
import axios from "axios";
import { Dispatch, PropsWithChildren, SetStateAction, createContext, useEffect, useMemo, useState } from "react";


interface IAppContextProps {
  tonPrice: number | undefined;
  walletAddress: string;
  userProfile: UserInfo | undefined;
  isLoading: boolean;
  latestToken: JettonToken | undefined;
  setWalletAddress: Dispatch<SetStateAction<string>>;
  setUserProfile: Dispatch<SetStateAction<UserInfo | undefined>>;
  fetchUserProfile: () => void;
}

export const AppContext = createContext<IAppContextProps>({
  tonPrice: undefined,
  walletAddress: '',
  userProfile: undefined,
  isLoading: false,
  latestToken: undefined,
  setWalletAddress: () => { },
  setUserProfile: () => { },
  fetchUserProfile: () => { }
});

export const AppContextProvider = (props: PropsWithChildren) => {
  const lp = useLaunchParams();

  const [tonPrice, setTonPrice] = useState<number>();
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [userProfile, setUserProfile] = useState<UserInfo>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [latestToken, setLatestToken] = useState<JettonToken>();

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null
    const connectWebSocket = () => {
      const token = `tma ${globalData.initDataRaw}`;
      const ws = new WebSocket(`${import.meta.env.VITE_WS_URL}/indexer/jetton/create_time?token=${encodeURIComponent(token)}`, []);
      // const ws = new WebSocket(`wss://d1d337ys729kgr.cloudfront.net/indexer/jetton/create_time?token=${encodeURIComponent(token)}`, []);

      ws.onopen = (ev) => {
        console.log('open', ev);

        // timer = setInterval(() => {
        //   ws.send('heart')
        // }, 1000);
      }
      ws.onclose = (ev) => {
        console.log('close', ev);
        // if (timer) {
        //   clearInterval(timer)
        //   timer = null
        // }
        // 尝试重新连接
        connectWebSocket()
      }
      ws.onmessage = (ev) => {
        const data = JSON.parse(ev.data) as JettonToken;

        console.log(data);
        setLatestToken(data);
      }
      ws.onerror = (ev) => {
        console.log('error', ev);
        if (timer) {
          clearInterval(timer)
          timer = null
        }
      }
    }
    connectWebSocket()
    return () => {
      // if (timer) {
      //   clearInterval(timer);
      // }
    }
  }, [])

  useEffect(() => {
    fetchTonPrice();

    const supplyTimer = setInterval(() => {
      fetchTonPrice();
    }, 1000 * 10);

    return () => clearInterval(supplyTimer);
  }, [])

  useEffect(() => {
    if (walletAddress && userProfile) {
      if (userProfile.user_address != walletAddress) {
        createUser({
          ...userProfile,
          user_address: walletAddress,
        });
      }
    }
  }, [
    walletAddress,
    userProfile
  ])

  const fetchTonPrice = async () => {
    try {
      const { data } = await axios.get(`https://www.okx.com/api/v5/public/mark-price?instType=SWAP&instId=TON-USDT-SWAP`);
      const price = data['data'][0]['markPx'];
      setTonPrice(price);
    }
    catch (ex) {
      console.log(ex)
      // setTonPrice(undefined);
    }
  }

  const userId = useMemo(() => {
    if (lp && lp.initData) {
      return lp.initData.user?.id;
    }
  }, [
    lp
  ])

  useEffect(() => {
    if (userId) {
      fetchUserProfile();
    }
  }, [
    userId
  ])

  const fetchUserProfile = async () => {
    if (userId) {
      setLoading(true);

      try {
        const { data } = await fetchUserInfo(userId);
        if (data) {
          setUserProfile(data as UserInfo);
        }
      }
      catch (ex) {
        console.log(ex)
        // setTonPrice(undefined);
      }

      setLoading(false);
    }
  }

  return (
    <>
      <AppContext.Provider
        value={{
          tonPrice,
          walletAddress,
          latestToken,
          setWalletAddress,
          userProfile,
          setUserProfile,
          fetchUserProfile,
          isLoading,
        }}
      >
        {props.children}
      </AppContext.Provider>
    </>
  );
};
