import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { FilterParams, JettonToken, TokenSort, TokenFilter, TokenOrder } from '@/lib/types';
import { fetchTokensHome } from '@/api/jettons';
import { formatBN } from '@/lib/format';
import { calculateMarketCap, shortenAddress } from '@/lib';
import { Loading } from '@/components/common/Loading';
import { AppContext } from '@/context';

import './index.less'

export function TokenList({
  params
}: {
  params: FilterParams
}) {
  const { orderBy, filterBy, sortBy, search } = params;
  const { walletAddress: address,userProfile } = useContext(AppContext);
   
  const [tokens, setTokens] = useState<JettonToken[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadTokens = async () => {
      try {
        setLoading(true);
        const res = await fetchTokensHome({
          // address,
          order_by: orderBy,
          token_filter: filterBy,
          sence: sortBy,
          jetton_name: search,
          user_uid: userProfile?.user_uid
        });
        if (res.data) {
          setTokens(res.data);
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
    }
    loadTokens();
  }, [
    orderBy,
    filterBy,
    sortBy,
    search,
  ]);

  if (loading) {
    return <Loading />
  }

  return (
    <div>
      {
        tokens.map((token, idx) => {
          return <Link to={`/trading/${token?.jetton_master_address}`} key={idx} style={{ display: 'block', margin: '8px 0px', textDecoration: 'none' }}>
            <TokenItem
              descrption={token?.jetton_description}
              avatar={<img src={token?.jetton_image} alt={'token_image'} />}
              name={token?.jetton_name}
              ticker={token?.jetton_symbol}
              lastTon={formatBN(token?.ton_amount)}
              lastJetton={formatBN(token?.jetton_amount)}
              creatorName={token?.creator_name != "" ? token?.creator_name : shortenAddress(token?.creator_address)}
              creatorAvatar={<img height={32} src={token?.creator_image != "" ? token?.creator_image : '/images/ic_avatar.png'} alt={'creator_image'} />}
            />
          </Link>
        })
      }
    </div>
  )
}

const TokenItem: React.FC<{
  avatar: React.ReactElement;
  creatorAvatar: React.ReactElement;
  name: string;
  ticker: string;
  creatorName: string;
  descrption: string;
  lastTon: number;
  lastJetton: number;
}> = (props) => {
  const {
    avatar,
    name,
    ticker,
    creatorAvatar,
    creatorName,
    descrption,
    lastTon,
    lastJetton,
  } = props;

  const { tonPrice } = useContext(AppContext);

  return (
    <div className='token-card-container'>
      <div className="user-card">
        <div className="user-avatar" >
          {React.cloneElement(avatar, { width: '80px' })}
        </div>
        <div className="user-details">
          <div className="user-ticker">{name} [${ticker}]</div>
          <div className='user-market-cap'>Market Cap: ${calculateMarketCap(lastTon, lastJetton, tonPrice ?? 0).toFixed(2)}</div>
          <div className="user-creator">
            <span>Created by:</span>
            <div className="creator-avatar" >
              {React.cloneElement(creatorAvatar, { width: 24, height: 24 })}
              {creatorName}
            </div>
          </div>
          <div className='user-descrption'>{descrption}</div>
        </div>
      </div>
    </div>
  )
}

