import { Header } from "@/components/Header"
import toast from "react-hot-toast"
import {
  ButtonHTMLAttributes,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  Suspense,
  lazy
} from "react"
import { drawLottery } from "@/contract"
import { useTonAddress, useTonConnectUI,ConnectedWallet } from "@tonconnect/ui-react"
import slotBg from "@/assets/slotBg.png"
import { getRandomInt, sleep } from "@/lib"
import { Spin, SpinRef, rewardMap } from "@/components/Lucky/Spin"
import { useLaunchParams } from "@telegram-apps/sdk-react"
import { getResult, getRemaining } from "@/api/lottery"
import { waitForNextTransaction, waitForTransaction } from "@/contract/utils"
import { Address } from "@ton/core"
import { AppContext } from '@/context'
const ImageComponent = lazy(() => import('@/components/common/ImageComponent'))
// import avatar1 from '@/assets/avater/avater1.png';
// import avatar2 from '@/assets/avater/avater2.png';
// import avatar3 from '@/assets/avater/avater3.png';
// import avatar4 from '@/assets/avater/avater4.png';
// import avatar5 from '@/assets/avater/avater5.png';
// import avatar6 from '@/assets/avater/avater6.png';
// import avatar7 from '@/assets/avater/avater7.png';
// import avatar8 from '@/assets/avater/avater8.png';
// import avatar9 from '@/assets/avater/avater9.png';
// import avatar10 from '@/assets/avater/avater10.png';
// import avatar11 from '@/assets/avater/avater11.png';
// import avatar12 from '@/assets/avater/avater12.png';
import './lucky.less';
interface Record {
  create_time: string;
  id: number;
  reward: string;
  user_name: string;
  user_uid: number;
}

const rewardList = [
  {
    avatar: '/avater/avater1.png',
    name: 'Amanda',
    reward: '1,000 POINTS'
  },
  {
    avatar: '/avater/avater2.png',
    name: 'Elliot',
    reward: '5,000 POINTS'
  },
  {
    avatar: '/avater/avater3.png',
    name: 'JoJa',
    reward: '1,000 POINTS'
  },
  {
    avatar: '/avater/avater4.png',
    name: 'Tanaka',
    reward: '10 TON'
  },
  {
    avatar: '/avater/avater5.png',
    name: 'Amanda',
    reward: '1,0000 POINTS'
  },
  {
    avatar: '/avater/avater6.png',
    name: 'Salini',
    reward: '100 TON'
  },
  {
    avatar: '/avater/avater7.png',
    name: 'Amanda',
    reward: '1,000 POINTS'
  },
  {
    avatar: '/avater/avater8.png',
    name: 'Abhimanyu',
    reward: '1 TON'
  },
  {
    avatar: '/avater/avater9.png',
    name: 'Carlos',
    reward: '1 TON'
  },
  {
    avatar: '/avater/avater10.png',
    name: 'Aaron',
    reward: '1,000 POINTS'
  },
  {
    avatar: '/avater/avater11.png',
    name: 'Martin',
    reward: '10 TON'
  },
  {
    avatar: '/avater/avater12.png',
    name: 'Chuchu',
    reward: '1 TON'
  },
]

function LuckyPage() {
  const maxLottry1Day = 10
  const { initData } = useLaunchParams()
  const [tonConnectUI] = useTonConnectUI()
  const tonAddress = useTonAddress()
  const [clientWidth, setClientWidth] = useState(0)
  const [pending, setPending] = useState(false)
  const [ops, setOps] = useState(0)
  const bgEl = useRef<HTMLDivElement>(null)
  const spinEl = useRef<SpinRef>(null)
  const { latestToken } = useContext(AppContext)
  const [rewardPeople, setrRewardPeople] = useState<{ reward: string, user_name: string }>()
  const [recordList, setRecordList] = useState<Record[]>([])
  const [currentIndex, setCurrentIndex] = useState(0)
  const timer = useRef<NodeJS.Timeout | number | null>(null)
  function calMt() {
    if (bgEl.current) {
      // const width = window.innerWidth
      const width = bgEl.current.clientWidth
      
      setClientWidth(width)
    }
  }
  const bgPt = useMemo(
    () => Math.round((clientWidth * 192) / 375),
    [clientWidth]
  )

  const tapPt = useMemo(
    () => Math.round((clientWidth * 105) / 375),
    [clientWidth]
  )

  const buttonMt = useMemo(
    () => Math.round((clientWidth * 35) / 375),
    [clientWidth]
  )
  const buttonHeight = useMemo(
    () => Math.round((clientWidth * 62) / 375),
    [clientWidth]
  )
  const remainingMt = useMemo(
    () => Math.round((clientWidth * 52) / 375),
    [clientWidth]
  )
  const recordsMt = useMemo(
    () => Math.round((clientWidth * 75) / 375),
    [clientWidth]
  )
  useEffect(() => {
    calMt()
    window.addEventListener("resize", calMt)
    return () => {
      window.removeEventListener("resize", calMt)
    }
  }, [bgEl])

  const onDraw = async () => {
    try {
      if (pending)
        throw Error("The lottery is in progress, please do not repeat the operation");
      setPending(true)
      if (!initData || !initData.user)
        throw Error("invalid initData");

      if (!tonConnectUI.account) {
        throw Error("Connect wallet first");
      }

      const res = await drawLottery(tonConnectUI, initData?.user.id)
      console.log(res);

      if (!res) throw Error('invalid transaction')

      spinEl.current?.drawPending()

      // const recipient = Address.parse(tonConnectUI.account.address);
      // const preTx = await waitForTransaction(recipient, res);

      // const nextTx = await waitForNextTransaction(recipient, preTx.hash().toString('hex'))
      // console.log(nextTx.hash().toString("hex"));
      // const inCell = nextTx.inMessage?.body
      // const slice = nextTx.inMessage?.body.beginParse()
      // console.log(inCell?.toBoc().toString('base64'));
      // const a = slice?.loadStringTail()
      // console.log(a);

      // if (a) {
      //   const reward = rewardMap[a]

      //   const rewardId =
      //     reward instanceof Array
      //       ? (reward[getRandomInt(0, reward.length - 1)])
      //       : reward
      //   await spinEl.current?.drawTo(rewardId)
      // }

      // const res = await getResult(initData?.user.id, tonConnectUI.account.address)

      // if (res && res != "timeout") {
      //   setOps(res.remaining)
      //   // const reward = rewardMap[res.record.reward]

      //   // const rewardId =
      //   //   reward instanceof Array
      //   //     ? (reward[getRandomInt(0, reward.length - 1)])
      //   //     : reward
      //   // await spinEl.current?.drawTo(rewardId)
      // }

    } catch (error) {
      toast.error(error + '', {
        id: "pumphub",
      })
      spinEl.current?.drawTo(3)
      setPending(false)
    }
  }

  useEffect(() => {
    const start = async () => {
      try {
        if (!initData || !initData.user)
          throw Error("invalid initData");
        if (!tonConnectUI.account) {
          throw Error("Connect wallet first");
        }
        if (latestToken) {
          // console.log(latestToken.user_uid,initData?.user.id,"*****");
          if (latestToken.reward != "Thanks") {
            setrRewardPeople(latestToken)
          }
          if (latestToken.user_uid == initData?.user.id) {
            clearTimeout(timer.current as NodeJS.Timeout | number)
            console.log(latestToken, '&&&&****');
            const reward = rewardMap[latestToken.reward]
            setPending(false)

            const rewardId =
              reward instanceof Array
                ? (reward[getRandomInt(0, reward.length - 1)])
                : reward
            await spinEl.current?.drawTo(rewardId)

            const res = await getResult(initData?.user.id, tonConnectUI.account.address)

            if (res && res != "timeout") {
              setOps(res.remaining)
            }
          }
        }
      } catch (error) {
        toast.error(error + '', {
          id: "pumphub",
        })
      } finally {
        setPending(false)
      }
    }
    start()
  }, [latestToken])

  // useEffect(() => {
  //   console.log(tonConnectUI,'tonConnectUI');
  //   if (tonConnectUI) {
  //     init()
  //   }
  // }, [tonConnectUI])
  useEffect(() => {
    console.log(tonConnectUI, "tonConnectUI");

    const handleStatusChange = (wallet: ConnectedWallet | null) => {
      if (wallet) {
        console.log("User is connected:", wallet);
        init(); // 用户连接时执行初始化操作
      } else {
        console.log("User has disconnected");
        setOps(0)
        // 处理用户断开连接的逻辑
      }
    };

    // 监听连接状态变化
    tonConnectUI.onStatusChange(handleStatusChange);

    return () => {
      // 清理监听器，避免内存泄漏
      // tonConnectUI.offStatusChange(handleStatusChange);
    };
  }, [tonConnectUI])
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % rewardList.length);
    }, 30000);

    // 清除定时器以防内存泄漏
    return () => clearInterval(interval);
  }, [rewardList.length])

  const init = useCallback(async () => {
    console.log(tonConnectUI.account,"$$$$$$");
    if (!initData || !initData.user || !tonConnectUI.account) return
    const res = await getRemaining(initData?.user.id, tonConnectUI.account.address)
    if (res != 'timeout')
      setOps(res.remaining)
    const recordList = res.record.filter((v: Record) => v.reward != "Thanks")
    setRecordList(recordList)
  }, [tonConnectUI, initData])

  useEffect(() => {
    init()
  }, [])



  const getTimeDifferenceInHours = (timestamp: string) => {
    // 将秒级时间戳转换为毫秒级
    const timestampInMilliseconds = Number(timestamp) * 1000;

    // 获取当前时间的时间戳（毫秒级）
    const now = Date.now();

    // 计算时间差，单位为毫秒
    const timeDifference = now - timestampInMilliseconds;

    // 将时间差转换为小时
    const differenceInHours = Math.floor(timeDifference / (1000 * 60 * 60))

    return differenceInHours;
  }

  return (
    <div className='w-full'>
      <Header />
      <div className='relative  '>
        <img src={slotBg} className='w-full' />
        <div style={{
          paddingTop: tapPt,
        }}
          className="absolute top-0 w-full flex justify-center items-center"
        >
          <div style={{ aspectRatio: '8 / 1' }} className="rounded flex items-center top-0 w-[55%] overflow-hidden bg-[#003886] px-1 relative">
            {/* 假数据 */}
            <div className="py-1 flex text-xs  w-full items-center marquee">
              {/* <img src={require("../assets/avater/avater1")} alt="" /> */}
              <Suspense fallback={<span>loading...</span>}>
                <ImageComponent url={rewardList[currentIndex].avatar} classType='w-5 rounded-full' />
              </Suspense>
              <span className="px-3">{rewardList[currentIndex].name}</span>
              <span className="uppercase text-[#2BBA68]">Win {rewardList[currentIndex].reward}</span>
            </div>
            {/* {
                rewardPeople && <span className="marquee">{rewardPeople?.user_name} <span className="text-[#2BBA68]">WIN {rewardPeople?.reward}å</span></span>
              } */}
          </div>
        </div>
        <div
          ref={bgEl}
          style={{
            paddingTop: bgPt,
          }}
          className='w-full absolute top-0 h-auto flex flex-col justify-center items-center'
        >
          {bgPt && (
            <>
              <Spin className='w-3/4' ref={spinEl} />
              <div
                style={{
                  marginTop: buttonMt,
                  height: buttonHeight,
                }}
                className={`w-full flex justify-center overflow-hidden bg-[url("./assets/drawButtonBg.png")] bg-contain bg-bottom bg-no-repeat`}
              >
                <DrawButton canDraw={!pending && Boolean(ops)} onDraw={onDraw}>
                  {pending ? 'PENDING' : 'DRAW IT'}
                </DrawButton>
              </div>
              <div
                style={{
                  marginTop: remainingMt,
                  boxShadow:
                    "1px 1px 3px 0px rgba(255, 255, 255, 0.50), 0px 2px 3px 0px rgba(0, 0, 0, 0.25) inset",
                }}
                className='text-xs sm:text-lg  bg-blue-500 px-3 py-2 rounded'
              >
                Opportunities remaining today: {ops}
              </div>
              <div
                style={{ marginTop: recordsMt }}
                className="px-2 w-full"
              >
                <div className={
                  " relative w-full h-[150px] bg-gradient-to-r from-[#91F8FFB2] to-[#1DA2EC] p-1 rounded-2xl "
                }>
                  <div className={
                    "w-full h-full  p-1 rounded-xl flex flex-col items-center bg-[#003886] px-5 pb-6  overflow-auto"
                  }>
                    {
                      recordList.map((v, index) => {
                        return (
                          <div className="w-full flex text-xs justify-between items-center pt-6" key={index}>
                            <div>
                              <span className="uppercase pr-2">you win</span>
                              <span className="text-[#2BBA68]">{v.reward} Rewards</span>
                            </div>
                            <div>
                              <span>{getTimeDifferenceInHours(v.create_time)} hour ago</span>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div
                    className="top-[-18px] w-3/4 h-[36px]  absolute translate-x-[50%] right-[50%]
                    bg-gradient-to-r from-[#91F8FFB2] to-[#1DA2EC] p-1 rounded-2xl
                    "
                  >
                    <div className="w-full h-full  p-1 rounded-xl flex flex-col items-center bg-[#003886]">
                      <span className="uppercase">Lucky records</span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div>
        </div>
      </div>
    </div>
  )
}

const DrawButton = ({
  canDraw,
  children,
  className,
  onDraw,
  ...props
}: { canDraw: boolean; onDraw: () => void } & PropsWithChildren &
  ButtonHTMLAttributes<HTMLButtonElement>) => {
  const width = (158 / 375) * 100
  const btnEl = useRef<HTMLButtonElement>(null)
  const [taping, setTaping] = useState(false)
  const handleDraw = (e: { preventDefault: () => void; stopPropagation: () => void }) => {

    e.preventDefault()
    e.stopPropagation()
    setTaping(false)
    onDraw?.()
  }
  const bgUrl = useMemo(() => canDraw ? 'bg-[url("./assets/drawButton.png")]' : 'bg-[url("./assets/drawButtonDisable.png")]', [canDraw])
  return (
    <div
      style={{
        width: `${width}%`,
        height: "90%",
      }}
      className={`overflow-hidden`}
    >
      <button
        ref={btnEl}
        onMouseDown={canDraw ? (e) => {
          e.stopPropagation()
          setTaping(true)
        } : () => { }}
        onTouchStart={canDraw ? (e) => {
          e.preventDefault()
          e.stopPropagation()
          setTaping(true)
        } : () => { }}
        onMouseUp={canDraw ? handleDraw : () => { }}
        onTouchEnd={canDraw ? handleDraw : () => { }}
        style={{
          transform: `translateY(${taping ? 10 : 0}px)`,
        }}
        className={`w-full h-full  ${bgUrl} bg-contain bg-center relative ${className}`}
        {...props}
      >

        <span className='absolute top-1 left-center'>{children}</span>
      </button>
    </div>
  )
}


export default LuckyPage
