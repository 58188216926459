import { useContext, useEffect, useState } from 'react'
import { calculateBasePoint, calculateBoostBase, calculateFarmBoost, calculateTradingPoint,calculateShareBoost } from '@/lib';

import { AppContext } from '@/context';
import { formatBN } from '@/lib/format';

import './index.less'

export const TradingSummary = () => {
  const [tradingPoints, setTradingPoints] = useState(0);
  const [farmBoost, setFarmBoost] = useState(0);

  const { userProfile } = useContext(AppContext);

  useEffect(() => {
    if (userProfile) {
      const { jetton_created, jetton_buy_volume, jetton_sell_volume, jetton_completed, referrals_amount, base_point, farm_boost } = userProfile;
      const buyVolume = formatBN(jetton_buy_volume);
      const sellVolume = formatBN(jetton_sell_volume);
      const boostBase = calculateBoostBase(jetton_created, buyVolume);
      const basePoint = calculateBasePoint(buyVolume, sellVolume);
      const farmBoost = calculateFarmBoost(boostBase, jetton_completed);
      const sharePoint = calculateShareBoost(base_point,farm_boost)
      setFarmBoost(farmBoost);
      // 本地测试加入basepoint
      // let basepoinTest = Number(localStorage.getItem("basePoint"))
      // console.log(localStorage.getItem("basePoint"),"^^^^^^");
      setTradingPoints(calculateTradingPoint(basePoint, farmBoost, referrals_amount, sharePoint))
    }
    else {
      setTradingPoints(0);
    }
  }, [userProfile]);


  return (
    <div className="home-trading-summary">
      <div className="trading-points">
        <span className="summary-number">{tradingPoints.toFixed(1)}</span>
        <span className="summary-text"> Trading points </span>
      </div>
      <div className="h-10 border-l border-dashed border-[#7B8090] mx-0"></div>
      <div className="farm-boost">
        <span className="summary-number"> {farmBoost.toFixed(1)}X</span>
        <span className="summary-text"> Farm boost </span>
      </div>
    </div>
  )
}
