import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { fetchTransactions } from "@/api/jettons";
import { JettonToken, TransactionRecord } from "@/lib/types";

import './index.less';
import { timeAgo } from "@/lib/time";
import { shortenAddress } from "@/lib";
import { formatNumber } from "@/lib/format";
import { Link } from "react-router-dom";
import { TON_VIEWER } from "@/lib/constants";
import { Loading } from "@/components/common/Loading";

export const TransactionHistory = ({ address, tokenInfo }: { address?: string, tokenInfo: JettonToken }) => {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState<TransactionRecord[]>([] as TransactionRecord[]);

  useEffect(() => {
    fetchHistory();

    const historyTimer = setInterval(() => {
      fetchHistory();
    }, 1000 * 10);

    return () => clearInterval(historyTimer);
  }, [address, tokenInfo.jetton_master_address])

  const fetchHistory = async () => {
    const TimeNow = new Date().getTime();
    // const TimeNowFiveMinutesAgo = TimeNow - 5 * 60 * 1000;
    try {
      setLoading(true);
      const res = await fetchTransactions({
        jetton_master_address: tokenInfo.jetton_master_address,
        user_address: address || '',
        start: 0,
        end: TimeNow,
      });
      if (res.data) {
        let transactions = res.data as TransactionRecord[];
        transactions = transactions
          .filter(t => parseFloat(t.jetton_amount) > 0 && parseFloat(t.ton_amount) > 0)
          .sort((a, b) => b.timestamp.localeCompare(a.timestamp));
        setTransactions(transactions);
        console.log(transactions)
      }
    } catch (e) {
      console.error(e);
    }
    finally {
      setLoading(false);
    }
  }

  return <Box mt={2}>
    <div className="home-transaction-title">Transaction History</div>

    {
      loading ? <Loading />
        : <>
          {
            transactions.map(el => {
              const {
                type,
                user_name,
                user_address,
                user_image,
                ton_amount,
                jetton_amount,
                timestamp,
                hash,
              } = el
              const time = timeAgo(parseInt(timestamp));

              return <Box mt={2} key={`tx-${hash}`}>
                <TransactionCard
                  token_image={user_image}
                  type={type}
                  time={time}
                  hash={hash}
                  amount={formatNumber(ton_amount, 6)}
                  value={formatNumber(jetton_amount, 3)}
                  title={user_name !== "" ? user_name : shortenAddress(user_address)}
                />
              </Box>
            })
          }
        </>
    }

  </Box>
}

type TransactionCardProps = {
  type: string;
  time: string;
  amount: string;
  value: string;
  token_image: string;
  title: string;
  hash: string;
};

const TransactionCard = ({ hash, token_image, type, time, amount, value, title }: TransactionCardProps) => {

  return (
    <Link to={`${TON_VIEWER}/transaction/${hash}`} target="_blank" style={{ textDecoration: 'none' }}>
      <Card sx={{ display: 'flex', mb: 1, bgcolor: '#181818', color: 'white', borderRadius: '16px' }}>
        <Avatar
          alt="Avatar"
          src={token_image}
          sx={{ width: 48, height: 48, m: 1 }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Box sx={{ flex: '1 0 auto', p: 1 }}>
            <Typography component="div" variant="subtitle1">
              {title}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" component="div">
              <Typography component="span" sx={{ color: type === 'sell' ? 'red' : 'green', pr: 1 }}>
                {type}
              </Typography>
              {time}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', pr: 2 }}>
          <Typography component="div" variant="subtitle1">
            {amount}TON
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" component="div">
            {value}
          </Typography>
        </Box>
      </Card>
    </Link>
  );
};
