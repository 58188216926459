import { TokenList } from "@/components/Pages/Home/TokenList";
import union from '@/assets/union.svg';

import './index.less';
import { TokenFilters } from "../TokenFilters";
import { useState } from "react";
import { FilterParams, TokenFilter, TokenOrder, TokenSort } from "@/lib/types";

export function HomeTokenList() {

  const [params, setParams] = useState<FilterParams>({
    search: "",
    filterBy: TokenFilter.ALL,
    sortBy: TokenSort.LASTEST,
    orderBy: TokenOrder.DESC,
  });

  const onSearch = (params: FilterParams) => {
    setParams(params);
  }

  return <div className='mt-3'>
    <TokenListBanner />
    <div className="">
      <TokenFilters onSearch={onSearch} params={params} />
      <TokenList params={params} />
    </div>
  </div>
}

export function TokenListBanner() {
  return <div className="flex justify-between">
    <div className="flex-1">
      <img src={union} width={'100%'} />
    </div>
    <div className="border-[#0297E8] flex-1 text-lg text-[#0297E8] font-bold text-center">Tokens</div>
  </div>
}
