import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { BONDING_CURVE } from '@/lib/constants';
import { JettonToken, TokenInput } from '@/lib/types';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { getTonBalance, mint } from '@/contract';
import { Address } from '@ton/ton';
import { Link, useNavigate } from 'react-router-dom';
import { calculateSupply, sleep } from '@/lib';
import { fetchTokens } from '@/api/jettons';
import { ImageUploader } from '@/components/common/ImageUploader';
import { WalletButton } from '@/components/common/WalletButton';

export const CreatePage = () => {
  const [isSubmitted, setSubmitted] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>();
  const navigate = useNavigate();

  const [tonConnectUI] = useTonConnectUI();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors }
  } = useForm<TokenInput>();

  const avatar = watch("avatar");
  const ticker = watch("ticker");
  const amount = watch("amount");

  const totalSupply = useMemo(() => {
    return calculateSupply(amount);
  }, [
    amount
  ])
  const percentSupply = useMemo(() => {
    return totalSupply / BONDING_CURVE * 100;
  }, [
    totalSupply
  ])

  useEffect(() => {
    fetchBalance();
  }, [
    tonConnectUI.account
  ])

  const fetchBalance = async () => {
    if (tonConnectUI.account) {
      const address = Address.parse(tonConnectUI.account.address);
      const balance = await getTonBalance(address);
      setBalance(balance);
    }
  }

  const onSubmit = async (data: TokenInput) => {
    if (!data.avatar) {
      toast.error("You need to upload token image");
      return;
    }

    setSubmitted(true);
  };

  const handleImageUpload = async (uri: string) => {
    setValue('avatar', uri);
  };

  const handleCreate = async () => {

    if (!tonConnectUI || !tonConnectUI.account) {
      toast.error("You need to connect wallet");
      return;
    }

    const input = getValues();
    if (input.amount > 0 && input.amount < 0.3) {
      toast.error("To mint jetton, at least 0.3 TON required.");
      return;
    }

    toast.loading("Please wait while create token...", {
      id: "pumphub"
    });

    try {

      const jettonAddress = await mint(tonConnectUI, input);

      // Check if token created from indexer
      let isSuccess = false;
      for (let i = 0; i < 60; i++) {
        isSuccess = await loadToken(jettonAddress);
        console.log(jettonAddress, isSuccess);
        if (isSuccess) {
          break;
        }

        await sleep(3000);
      }

      if (isSuccess) {
        toast.success("Your token has been created successfully", {
          id: "pumphub"
        });

        navigate('/home');
        return;
      }

      throw Error("Get timeout...");
    }
    catch (ex) {
      console.log(ex);
      const msg = (ex as Error).message;
      if (msg.includes("Reject request")) {
        toast.dismiss("pumphub");
      }
      else {
        toast.error("Get error while create token", {
          id: "pumphub"
        });
      }
    }

  }

  const loadToken = async (address: string) => {
    try {
      const res = await fetchTokens({ address });
      const tokenInfo = res?.['data']?.[0] as JettonToken;
      return tokenInfo.create_time > 0;
    } catch (e) {
      console.log(e)
      return false;
    }
  }

  return (

    <div className='w-full min-h-screen relative'>

      <div className='absolute top-0 left-0 right-0'>
        <img src='/images/bg_world.png' alt='World' className='w-full' />
      </div>

      <div className='w-full'>

        {
          !isSubmitted ?

            <form onSubmit={handleSubmit(onSubmit)}>

              <div className='sticky top-0 z-[100]'>

                <div className='w-full flex gap-2 justify-between p-4 bg-black/20'>
                  <Link to={'/'}>
                    <img src='/images/ic_logo.svg' alt='Logo' className='h-7' />
                  </Link>

                  <div className='w-36'>
                    <WalletButton />
                  </div>
                </div>

              </div>

              <div className='w-full px-4 my-8'>
                <div className='relative w-full flex justify-center pb-16'>

                  <ImageUploader avatar={avatar}
                    setAvatar={handleImageUpload} />

                  <img src='/images/ic_letter.svg' alt='Letter' className='h-16 absolute bottom-4 left-6' />
                </div>

                <div className='w-full flex flex-col gap-4'>

                  <div className='w-full flex flex-col gap-2'>
                    <label className='pl-4 text-primary-100 text-md'>Name</label>
                    <input
                      type="text"
                      className='form-input'
                      {...register("name", {
                        required: "You need to input name",
                        maxLength: {
                          message: "Max length is 20",
                          value: 20
                        }
                      })}
                    />
                    {
                      errors.name &&
                      <span className='text-error-100 text-sm'>
                        {errors.name.message}
                      </span>
                    }
                  </div>

                  <div className='w-full flex flex-col gap-2'>
                    <label className='pl-4 text-primary-100 text-md'>Ticker</label>
                    <input
                      type="text"
                      className='form-input'
                      {...register("ticker", {
                        required: "You need to input ticker",
                        maxLength: {
                          message: "Max length is 6",
                          value: 6
                        }
                      })}
                    />
                    {
                      errors.ticker &&
                      <span className='text-error-100 text-sm'>
                        {errors.ticker.message}
                      </span>
                    }
                  </div>

                  <div className='w-full flex flex-col gap-2'>
                    <label className='pl-4 text-primary-100 text-md'>Description</label>
                    <textarea
                      rows={4}
                      className='form-input'
                      {...register("description", {
                        required: "You need to input description",
                        maxLength: {
                          message: "Max length is 500",
                          value: 500
                        }
                      })}
                    />
                    {
                      errors.description &&
                      <span className='text-error-100 text-sm'>
                        {errors.description.message}
                      </span>
                    }
                  </div>

                  <div className='w-full flex flex-col gap-2'>
                    <label className='pl-4 text-primary-100 text-md'>Telegram Link</label>
                    <input
                      type="text"
                      className='form-input'
                      {...register("telegram", {
                        maxLength: {
                          message: "Max length is 100",
                          value: 100
                        }
                      })}
                    />
                    {
                      errors.telegram &&
                      <span className='text-error-100 text-sm'>
                        {errors.telegram.message}
                      </span>
                    }
                  </div>

                  <div className='w-full flex flex-col gap-2'>
                    <label className='pl-4 text-primary-100 text-md'>Twitter Link</label>
                    <input
                      type="text"
                      className='form-input'
                      {...register("twitter", {
                        maxLength: {
                          message: "Max length is 100",
                          value: 100
                        }
                      })}
                    />
                    {
                      errors.twitter &&
                      <span className='text-error-100 text-sm'>
                        {errors.twitter.message}
                      </span>
                    }
                  </div>

                  <div className='w-full flex flex-col gap-2'>
                    <label className='pl-4 text-primary-100 text-md'>Website</label>
                    <input
                      type="text"
                      className='form-input'
                      {...register("website", {
                        maxLength: {
                          message: "Max length is 100",
                          value: 100
                        }
                      })}
                    />
                    {
                      errors.website &&
                      <span className='text-error-100 text-sm'>
                        {errors.website.message}
                      </span>
                    }
                  </div>

                </div>

                <button type='submit'
                  className='btn-solid w-full mt-8 py-2'>
                  Next
                </button>
              </div>

            </form>
            : <div className='w-full'>

              <div className='sticky top-0 z-[100]'>

                <div className='w-full flex gap-2 justify-between p-4 bg-black/20'>
                  <button type='button' className='flex gap-2 items-center'
                    onClick={() => setSubmitted(false)}>
                    <img src='/images/ic_back.svg' alt='Back' className='h-3' />
                    <span className='text-white text-sm'>Back</span>
                  </button>

                  <Link to={'/'}>
                    <img src='/images/ic_logo.svg' alt='Logo' className='h-7' />
                  </Link>

                  <div></div>
                </div>

              </div>

              <div className='w-full px-4 my-8'>

                <div className='p-2 w-full flex flex-col gap-2 items-center justify-center mb-8'>
                  <p className='text-2xl text-center'>
                    How many ${ticker} you want to buy?
                  </p>
                  <span className='text-sm text-dark-700'>
                    (Optional)
                  </span>
                </div>

                <div className='bg-dark-200 rounded-md px-3 py-5 opacity-80 flex flex-col gap-4 text-sm mb-24'>

                  <div className='w-full flex justify-between gap-4'>
                    <input type='text' placeholder='0.0 (optional)'
                      className='text-white bg-transparent focus:outline-none'
                      {...register('amount', {
                        valueAsNumber: true,
                      })} />
                    <div className='flex items-center gap-2'>
                      <span className='text-white'>TON</span>
                      <img src='/images/ic_coin.png' alt='Coin' className='w-6 h-6' />
                    </div>
                  </div>

                  <div className='w-full h-0.5 border border-dark-400 border-dashed' />

                  <div className='w-full flex justify-between gap-4'>
                    <span className='text-dark-700'>Balance:</span>
                    <span className='text-white text-md'>{balance?.toFixed(2) ?? "-"} TON</span>
                  </div>
                  <div className='w-full flex justify-between gap-4'>
                    <span className='text-dark-700'>You Receive:</span>
                    <span className='text-white'>{totalSupply.toFixed(2)} {ticker}</span>
                  </div>
                  <div className='w-full flex justify-between gap-4'>
                    <span className='text-dark-700'>Percentage of supply:</span>
                    <span className='text-white'>{percentSupply.toFixed(2)}%</span>
                  </div>

                </div>

                <div className='w-full flex flex-col gap-4 mb-4'>
                  <button type='button' className='btn-craft'
                    onClick={handleCreate}>
                    <img src='/images/ic_plus.svg' alt='Plus' className='w-8 h-8' />
                    <span className='text-dark-100 font-semibold text-lg'>
                      Create Token
                    </span>
                  </button>
                  <span className='text-primary-300 text-xs text-center'>
                    Cost to deploy: ~0.2 TON
                  </span>
                </div>

                <p className='text-dark-700 text-xs text-center p-4'>
                  Tip: lt's optional, but buying some amount of coinscan
                  help protect your coin from snipers
                </p>
              </div>

            </div>
        }

      </div>

    </div>

  );
};