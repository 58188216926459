import { Outlet } from 'react-router-dom';

export function Layout() {
  return (
    <div className="flex flex-col flex-grow">
      <main className="flex-grow bg-dark-100 text-white">
        <Outlet />
      </main>
    </div>
  )
}

