/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios';
import { API_URL, globalData } from "@/lib/constants";

interface CustomAxiosInstance extends AxiosInstance {
  <T = any, R = AxiosResponse<T>, D = any>(config: AxiosRequestConfig<D>): Promise<T>;
  <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<T>;
}

const request: CustomAxiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 10000,
});

// request interceptors
request.interceptors.request.use(
  (config) => {
    if ('post' === config.method) {
      config.headers['Authorization'] = `tma ${globalData.initDataRaw}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// response interceptors
request.interceptors.response.use(
  response => {
    const res = response.data;
    return res;
  },
  error => {
    console.error(error);
    return Promise.reject(error);
  }
);

export default request;
