import { Address, beginCell, contractAddress, storeStateInit, toNano, fromNano, internal, Cell, Transaction, Message, storeMessage } from "@ton/core";
import { FactoryJetton, loadStateInit, storeMint } from "./FactoryJetton_FactoryJetton";
import { FactoryJettonWallet, storeClaim, storeTokenBurn, storeTokenTransfer } from "./FactoryJetton_FactoryJettonWallet";
import { TonConnectUI, Wallet } from "@tonconnect/ui-react";
import { TokenInput } from "@/lib/types";
import { Factory, Asset, PoolType, VaultJetton, VaultNative, SwapParams } from '@dedust/sdk';
import { TonClient } from "@ton/ton";

import { buildOnchainMetadata, waitForContractDeploy, waitForNextTransaction, waitForTransaction } from "./utils";
import { DEDUST_ADDRESS, globalData, OWNER_ADDRESS } from "@/lib/constants";
import { sleep } from "@/lib";
import { ChildJetton } from "./FactoryJetton_ChildJetton";
import { ChildJettonWallet } from "./FactoryJetton_ChildJettonWallet";
import { loadDrawLottery, Lottery, storeDrawLottery } from "./tact_Lottery";
import { getResult } from "@/api/lottery";
import toast from "react-hot-toast";

const workchain = 0;
const MINT_GAS_FEE = toNano("0.3");
const SWAP_GAS_FEE = toNano("0.3");
const BUY_GAS_FEE = toNano("0.3");
const SELL_GAS_FEE = toNano("0.3");
const CLAIM_GAS_FEE = toNano("0.3");


export function getTonClient() {
  const tonClient = new TonClient({
    endpoint: globalData.tonEndpoint ?? ""
  });
  return tonClient;
}

export async function getTonBalance(owner: Address) {
  const tonClient = getTonClient();
  const rawBalance = await tonClient.getBalance(owner);
  const balance = parseFloat(fromNano(rawBalance));
  return balance;
}

export async function getBalance(owner: Address, jettonMasterAddr: Address) {
  const tonClient = getTonClient();

  const jettonWallet = await FactoryJettonWallet.fromInit(owner, jettonMasterAddr);
  const jettonWalletContract = tonClient.open(jettonWallet);

  const ret = await jettonWalletContract.getGetWalletData();
  const balance = parseFloat(fromNano(ret.balance));
  return balance;
}

export async function getTotalSupply(jettonMasterAddr: Address) {
  const tonClient = getTonClient();

  const jettonMaster = await FactoryJetton.fromAddress(jettonMasterAddr);
  const jettonMasterContract = tonClient.open(jettonMaster);

  const ret = await jettonMasterContract.getGetJettonData();
  const totalSupply = parseFloat(fromNano(ret.total_supply));
  return totalSupply;
}

export async function getLaunched(jettonMasterAddr: Address) {
  const tonClient = getTonClient();

  const jettonMaster = await FactoryJetton.fromAddress(jettonMasterAddr);
  const jettonMasterContract = tonClient.open(jettonMaster);

  const ret = await jettonMasterContract.getGetG();
  return ret.finish_jetton && ret.finish_ton;
}

export async function getTransferable(jettonWalletAddr: Address) {
  const tonClient = getTonClient();

  const jettonWallet = await FactoryJettonWallet.fromAddress(jettonWalletAddr);
  const jettonWalletContract = tonClient.open(jettonWallet);

  const ret = await jettonWalletContract.getGetG();
  return ret.transferable;
}

function packSwapParams(options: SwapParams) {
  const { deadline, recipientAddress, referralAddress, fulfillPayload, rejectPayload } = options;
  return beginCell()
    .storeUint(deadline ?? 0, 32)
    .storeAddress(recipientAddress ?? null)
    .storeAddress(referralAddress ?? null)
    .storeMaybeRef(fulfillPayload ?? null)
    .storeMaybeRef(rejectPayload ?? null)
    .endCell();
}


export async function mint(tonConnectUI: TonConnectUI, input: TokenInput): Promise<string> {
  const tonClient = getTonClient();

  if (!tonConnectUI.account) {
    throw Error("Connect wallet first");
  }

  const content = buildOnchainMetadata({
    name: input.name,
    symbol: input.ticker,
    description: input.description,
    decimals: "9",
    image: input.avatar,
    website: input.website,
    telegram: input.telegram,
    twitter: input.twitter,
  });

  const jettonMaster = await FactoryJetton.init(content);
  const jettonMasterAddr = contractAddress(workchain, jettonMaster);

  const mintAmount = input.amount ? toNano(input.amount) : 0n;

  const mintMsg = beginCell()
    .store(
      storeMint({
        $$type: "Mint",
        ton_amount: mintAmount,
      })
    )
    .endCell();

  const value = (mintAmount + MINT_GAS_FEE).toString();

  const initState = beginCell().store(storeStateInit({
    code: jettonMaster.code,
    data: jettonMaster.data,
  })).endCell();

  const validUntil = Math.floor(Date.now() / 1000) + 600;
  await tonConnectUI.sendTransaction({
    validUntil,
    messages: [
      {
        address: jettonMasterAddr.toString(),
        amount: value,
        stateInit: initState.toBoc().toString("base64"),
        payload: mintMsg.toBoc().toString("base64")
      }
    ],
  });

  await waitForContractDeploy(jettonMasterAddr, tonClient);

  return jettonMasterAddr.toString();
}

export async function buyToken(tonConnectUI: TonConnectUI, jettonMasterAddress: Address, amount: number) {
  if (!tonConnectUI.account) {
    throw Error("Connect wallet first");
  }

  const recipient = Address.parse(tonConnectUI.account.address);
  const mintTonAmount = toNano(amount);

  const mintMsg = beginCell()
    .store(
      storeMint({
        $$type: "Mint",
        ton_amount: mintTonAmount,
      })
    )
    .endCell();

  const validUntil = Math.floor(Date.now() / 1000) + 600;
  const ret = await tonConnectUI.sendTransaction({
    validUntil,
    messages: [
      {
        address: jettonMasterAddress.toString(),
        amount: (mintTonAmount + BUY_GAS_FEE).toString(),
        payload: mintMsg.toBoc().toString("base64")
      }
    ],
  });

  await waitForTransaction(recipient, ret.boc);
  await sleep(3000);
}

export async function sellToken(tonConnectUI: TonConnectUI, jettonMasterAddress: Address, amount: number) {
  if (!tonConnectUI.account) {
    throw Error("Connect wallet first");
  }

  const recipient = Address.parse(tonConnectUI.account.address);
  const jettonWallet = (await FactoryJettonWallet.fromInit(recipient, jettonMasterAddress)).address;
  const amountBN = toNano(amount);

  const burnMsg = beginCell()
    .store(
      storeTokenBurn({
        $$type: "TokenBurn",
        query_id: 0n,
        amount: amountBN,
        response_destination: recipient,
        custom_payload: null
      })
    )
    .endCell();

  const validUntil = Math.floor(Date.now() / 1000) + 600;
  const ret = await tonConnectUI.sendTransaction({
    validUntil,
    messages: [
      {
        address: jettonWallet.toString(),
        amount: SELL_GAS_FEE.toString(),
        payload: burnMsg.toBoc().toString("base64")
      },
    ],
  });

  await waitForTransaction(recipient, ret.boc);
  await sleep(3000);
}

export async function claimToken(tonConnectUI: TonConnectUI, jettonMasterAddress: Address) {
  if (!tonConnectUI.account) {
    throw Error("Connect wallet first");
  }

  const recipient = Address.parse(tonConnectUI.account.address);
  const jettonWallet = (await FactoryJettonWallet.fromInit(recipient, jettonMasterAddress)).address;

  const claimMsg = beginCell().storeUint(0, 32).storeStringTail('Claim').endCell();

  const validUntil = Math.floor(Date.now() / 1000) + 600;
  const ret = await tonConnectUI.sendTransaction({
    validUntil,
    messages: [
      {
        address: jettonWallet.toString(),
        amount: CLAIM_GAS_FEE.toString(),
        payload: claimMsg.toBoc().toString("base64")
      }
    ],
  });

  await waitForTransaction(recipient, ret.boc);
  await sleep(3000);
}

export async function swapTokenWithTon(tonConnectUI: TonConnectUI, jettonMasterAddress: Address, amount: number) {
  if (!tonConnectUI.account) {
    throw Error("Connect wallet first");
  }

  const tonClient = getTonClient();
  const recipient = Address.parse(tonConnectUI.account.address);
  const amountBN = toNano(amount);

  const jettonMaster = tonClient.open(await FactoryJetton.fromAddress(jettonMasterAddress));
  const jettonMasterContent = (await jettonMaster.getGetJettonData()).content;
  const childAddress = (await ChildJetton.fromInit(OWNER_ADDRESS, jettonMasterAddress, jettonMasterContent)).address;

  const TON = Asset.native();
  const JETTON = Asset.jetton(childAddress);

  const dedustFactory = tonClient.open(Factory.createFromAddress(DEDUST_ADDRESS));
  const nativeVault = await dedustFactory.getNativeVault();
  const poolContract = tonClient.open(await dedustFactory.getPool(PoolType.VOLATILE, [TON, JETTON]));

  const swapMsg = beginCell()
    .storeUint(VaultNative.SWAP, 32)
    .storeUint(0, 64)
    .storeCoins(amountBN)
    .storeAddress(poolContract.address)
    .storeUint(0, 1)
    .storeCoins(0)  // limit
    .storeMaybeRef(null)  // packSwapStep
    .storeRef(packSwapParams({ recipientAddress: recipient }))
    .endCell()

  const validUntil = Math.floor(Date.now() / 1000) + 600;
  const ret = await tonConnectUI.sendTransaction({
    validUntil,
    messages: [
      {
        address: nativeVault.address.toString(),
        amount: (amountBN + SWAP_GAS_FEE).toString(),
        payload: swapMsg.toBoc().toString("base64")
      },
    ],
  });

  await waitForTransaction(recipient, ret.boc);
  await sleep(3000);
}

export async function swapTokenWithJetton(tonConnectUI: TonConnectUI, jettonMasterAddress: Address, amount: number) {
  if (!tonConnectUI.account) {
    throw Error("Connect wallet first");
  }

  const tonClient = getTonClient();
  const recipient = Address.parse(tonConnectUI.account.address);

  const jettonMaster = tonClient.open(await FactoryJetton.fromAddress(jettonMasterAddress));
  const jettonMasterContent = (await jettonMaster.getGetJettonData()).content;
  const childAddress = (await ChildJetton.fromInit(OWNER_ADDRESS, jettonMasterAddress, jettonMasterContent)).address;
  const childWallet = (await ChildJettonWallet.fromInit(recipient, childAddress)).address;

  const amountBN = toNano(amount);

  const TON = Asset.native();
  const JETTON = Asset.jetton(childAddress);

  const dedustFactory = tonClient.open(Factory.createFromAddress(DEDUST_ADDRESS));
  const jettonVault = await dedustFactory.getJettonVault(childAddress);
  const poolContract = tonClient.open(await dedustFactory.getPool(PoolType.VOLATILE, [TON, JETTON]));

  const transferPayload = beginCell()
    .storeUint(VaultJetton.SWAP, 32)
    .storeAddress(poolContract.address)
    .storeUint(0, 1) // reserved
    .storeCoins(0)  // limit
    .storeMaybeRef(null)  // packSwapStep
    .storeRef(packSwapParams({ recipientAddress: recipient }))
    .endCell();

  const swapMsg = beginCell()
    .store(
      storeTokenTransfer({
        $$type: 'TokenTransfer',
        query_id: 0n,
        amount: amountBN,
        destination: jettonVault.address,
        response_destination: recipient,
        custom_payload: null,
        forward_amount: toNano("0.25"),
        forward_payload: beginCell().storeBit(true).storeRef(transferPayload).endCell()
      })
    )
    .endCell();

  const validUntil = Math.floor(Date.now() / 1000) + 600;
  const ret = await tonConnectUI.sendTransaction({
    validUntil,
    messages: [
      {
        address: childWallet.toString(),
        amount: toNano("0.3").toString(),
        payload: swapMsg.toBoc().toString("base64")
      },
    ],
  });

  await waitForTransaction(recipient, ret.boc);
  await sleep(3000);
}

export async function drawLottery(tonConnectUI: TonConnectUI, uid: number) {
  if (!tonConnectUI.account) {
    throw Error("Connect wallet first");
  }

  // const walletAddress = Address.parse(tonConnectUI.account.address);
  // const client = getTonClient();
  // const state = await client.getContractState(walletAddress);

  // if (!state.lastTransaction) return
  // const { lt: lastLt, hash: lastHash } = state.lastTransaction;
  // console.log(lastHash, 'lastHash');

  // const lastTx = await client.getTransaction(walletAddress, lastLt, lastHash);

  // if (lastTx) {

  //   console.log(lastTx.prevTransactionHash.toString(16), 'pre');


  //   console.log(lastTx.hash().toString("hex"));
  //   const inCell = lastTx.inMessage?.body
  //   const slice = lastTx.inMessage?.body.beginParse()
  //   // console.log(inCell?.toBoc().toString('base64'));
  //   const a = slice?.loadStringTail()
  //   console.log(a)
  //   return a
  // }

  const lottery = await Lottery.init()

  const lotteryAddr = contractAddress(workchain, lottery);

  const drawMsg = beginCell().store(
    storeDrawLottery({
      $$type: "DrawLottery"
    })
  ).endCell()
  // const value = SWAP_GAS_FEE.toString()
  const value = toNano(1.02).toString()
  const initState = beginCell().store(storeStateInit({
    code: lottery.code,
    data: lottery.data,
  })).endCell();
  const recipient = Address.parse(tonConnectUI.account.address);
  const validUntil = Math.floor(Date.now() / 1000) + 600;
  const ret = await tonConnectUI.sendTransaction({
    validUntil,
    messages: [
      {
        address: lotteryAddr.toString(),
        amount: value,
        stateInit: initState.toBoc().toString("base64"),
        payload: drawMsg.toBoc().toString("base64")
      }
    ],
  });
  return ret.boc
  // const preTx = await waitForTransaction(recipient, ret.boc);

  // const nextTx = await waitForNextTransaction(recipient, preTx.hash().toString('hex'))
  // console.log(nextTx.hash().toString("hex"));
  // const inCell = nextTx.inMessage?.body
  // const slice = nextTx.inMessage?.body.beginParse()
  // console.log(inCell?.toBoc().toString('base64'));
  // const a = slice?.loadStringTail()
  // return a
}

// export async function drawLottery(tonConnectUI: TonConnectUI,) {
  
//   // const slice = Cell.fromBase64("te6cckEBAQEADQAAFjEwMDAgUG9pbnRzBl0ioQ==").beginParse()
//   // console.log(slice.loadStringTail());
//   // return slice.loadStringTail()
//   const lottery = await Lottery.init()

//   const lotteryAddr = contractAddress(workchain, lottery);

//   const drawMsg = beginCell().store(
//     storeDrawLottery({
//       $$type: "DrawLottery"
//     })
//   ).endCell()
//   // const value = SWAP_GAS_FEE.toString()
//   const value = toNano(0.01).toString()
//   const initState = beginCell().store(storeStateInit({
//     code: lottery.code,
//     data: lottery.data,
//   })).endCell();

//   // const recipient = Address.parse(tonConnectUI.account.address);
//   const validUntil = Math.floor(Date.now() / 1000) + 600;
//   const receipt = await tonConnectUI.sendTransaction({
//     validUntil,
//     messages: [
//       {
//         address: lotteryAddr.toString(),
//         amount: value,
//         stateInit: initState.toBoc().toString("base64"),
//         payload: drawMsg.toBoc().toString("base64")
//       }
//     ],
//   });
//   return receipt
//   // toast.loading("Please wait...", {
//   //   id: "pumphub"
//   // });


// }