import { Link } from "react-router-dom"

import TokenCard from "@/components/common/TokenCard"
import { JettonToken } from "@/lib/types"
import { formatBN } from "@/lib/format"
import { isEmpty, shortenAddress } from "@/lib"

export const TokenInfo = ({
  token
}: {
  token: JettonToken
}
) => {

  const { total_supply, creator_name, creator_address, ton_amount, jetton_amount, creator_image, jetton_master_address, jetton_symbol, jetton_image, jetton_name, jetton_description, telegram, website, twitter } = token;

  return (
    <div className="w-full flex flex-col gap-3">
      <TokenCard
        to={`/trading/${jetton_master_address}`}
        avatar={<img src={jetton_image} alt={'avatar'} />}
        name={jetton_name}
        ticker={jetton_symbol}
        totalSupply={formatBN(total_supply)}
        lastTon={formatBN(ton_amount)}
        lastJetton={formatBN(jetton_amount)}
        creatorName={creator_name != "" ? creator_name : shortenAddress(creator_address)}
        creatorAvatar={<img height={32} src={creator_image != "" ? creator_image : '/images/ic_avatar.png'} alt={'creator_image'} />}
      />

      <div className="w-full flex justify-between gap-3 items-center">

        {
          !isEmpty(telegram) && <Link to={telegram} target="_blank">
            <span className="text-dark-600 text-sm underline">Telegram</span>
          </Link>
        }

        {
          !isEmpty(twitter) && <Link to={twitter} target="_blank">
            <span className="text-dark-600 text-sm underline">Twitter</span>
          </Link>
        }

        {
          !isEmpty(website) && <Link to={website} target="_blank">
            <span className="text-dark-600 text-sm underline">Website</span>
          </Link>
        }

      </div>

      <div className='w-full'>
        <div className='text-primary-100 text-sm mb-2'>Description:</div>
        <div className='bg-dark-300 w-full p-3 rounded-lg text-white'>
          {jetton_description}
        </div>
      </div>
    </div>
  )
}