import { useContext, useEffect, useState } from 'react';

import Logo from '@/assets/ic2_pumphub.svg'
import PunpIcon from '@/assets/punpIcon.svg'

import { useNavigate, useParams } from 'react-router-dom';
import { useLaunchParams } from '@telegram-apps/sdk-react';
import toast from 'react-hot-toast';
import { AppContext } from '@/context';

import './index.less'
import { globalData } from '@/lib/constants';

export function Claim() {

  const lp = useLaunchParams();
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user_id } = useParams();
  console.log(user_id);

  const { userProfile, isLoading } = useContext(AppContext);

  useEffect(() => {
    if (userProfile) {
      navigate('/home',{ replace: true })
    }
  }, [
    userProfile
  ])


  return <div className='claim-container'>
    <img className='claim-logo' src={PunpIcon} width={44} alt="PunpIcon" />
    <img className='claim-title' src={Logo} height={36} alt="logo" />
    <span className='claim-tap uppercase'>Earn $PUMP With Social Engagements</span>
    <div className='claim-user'>
      <span className='claim-earn uppercase'>Hello, Dear @{userProfile?.user_name}</span>
    </div>
  
    <button className='justify-center items-center flex' type='button' onClick={()=>navigate("/check",{ replace: true })}
      id='claim-btn'>
       Claim your rewards
    </button>
  </div>
}
