import { TokenSort, TokenFilter, TokenOrder } from "@/lib/types";
import request from "./request";
import { API_URL } from "@/lib/constants";

export const fetchHighestGrowthTokens = async () => {
    return await request.post(`${API_URL}/indexer/jetton/volume_24h`, {
        "only_incomplete": true,
        "page": 1,
        "page_size": 1,
        "order_by": "Desc",
    });
}

export const fetchTokensHome = async ({
    jetton_name,
    // address,
    order_by,
    token_filter,
    sence,
    user_uid
}: { jetton_name?: string, order_by?: TokenOrder, token_filter?: TokenFilter, sence?: TokenSort, user_uid?: number }) => {
    let creator_address = null
    // let uid = null
    if (token_filter === TokenFilter.ALL) {
        creator_address = 0
    } 
    // else if (token_filter === TokenFilter.MY && address) {
    //     creator_address = address
    // } 
    else if (token_filter === TokenFilter.MY ) {
        creator_address = user_uid
    } 

    if (TokenSort.LASTEST === sence) {
        return await request.post(`${API_URL}/indexer/jetton/create_time`, {
            "creator_user_id": creator_address,
            "only_incomplete": true,
            "order_by": order_by,
            "jetton_name": jetton_name,
            "page": 1,
            "page_size": 100,
        });
    }
    else if (TokenSort.ONE_DAY === sence) {
        return await request.post(`${API_URL}/indexer/jetton/volume_24h`, {
            "creator_user_id": creator_address,
            "only_incomplete": true,
            "order_by": order_by,
            "jetton_name": jetton_name,
            "page": 1,
            "page_size": 100,
        });
    }
    else if (TokenSort.MKTCAP === sence) {
        return await request.post(`${API_URL}/indexer/jetton/total_supply`, {
            "creator_user_id": creator_address,
            "only_incomplete": true,
            "order_by": order_by,
            "jetton_name": jetton_name,
            "page": 1,
            "page_size": 100,
        });
    }
    return await request.post(`${API_URL}/indexer/jetton/volume_24h`, {
        "creator_user_id": creator_address,
        "only_incomplete": true,
        "order_by": order_by,
        "jetton_name": jetton_name,
        "page": 1,
        "page_size": 100
    });
}

export const fetchTokens = async (
    {
        address,
        order_by,
        name,
    }: { address?: string, order_by?: TokenOrder, name?: string }
) => {
    return await request.post(`${API_URL}/indexer/jetton/create_time`, {
        "jetton_master_address": address ?? "",
        "jetton_symbol": "",
        "jetton_name": name ?? "",
        "user_address": "",
        "only_incomplete": true,
        "order_by": order_by,
        "page": 1,
        "page_size": 100
    });
}

export const fetchTransactions = async (
    params: {
        jetton_master_address: string,
        user_address: string,
        start: number,
        end: number,
    }
) => {
    return await request.post(`${API_URL}/indexer/jetton/transactions`, params);
}

export const fetchHolders = async (
    params: {
        jetton_master_address: string,
        user_address: string,
        page: number,
        page_size: number,
    }
) => {
    return await request.post(`${API_URL}/indexer/jetton/holders`, params);
}

export const fetchLeaderboard = async () => {
    return await request.post(`${API_URL}/indexer/user/trading_point`);
}
