import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './index.less';

type LinkButtonProps = {
  to: string;
  className?: string;
  children: React.ReactNode;
  external?: boolean;
};

export const LinkButton = (props: PropsWithChildren<LinkButtonProps>) => {
  const { to, className, children, external, ...restProps } = props
  const buttonClass = classNames('link-button', className);
  const handleClick = (event: { preventDefault: () => void; }) => {
    if (external) {
      event.preventDefault();
      // @ts-expect-error fix it
      window.Telegram.WebApp.openLink(to, '_blank');
    }
  };

  if (external) {
    return (
      <a href={to} className={buttonClass} onClick={handleClick} {...restProps}>
        {children}
      </a>
    );
  }
  return (
    <Link to={to} className={buttonClass} {...restProps}>
      {children}
    </Link>
  );
};
