import React, { useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { LinkButton } from '../LinkButton';
import { calculateCurrentGrowth, calculateMarketCap } from '@/lib';
import { AppContext } from '@/context';

import Hit from '@/assets/hit.svg';
import UpIcon from '@/assets/up.svg';
import './index.less';

interface TokenCardProps {
  avatar: React.ReactElement;
  creatorAvatar: React.ReactElement;
  name: string;
  ticker: string;
  totalSupply: number;
  creatorName: string;
  to: string;
  lastTon: number;
  lastJetton: number;
}

const TokenCard: React.FC<TokenCardProps> = (props) => {
  const {
    to,
    avatar,
    name,
    ticker,
    creatorAvatar,
    creatorName,
    totalSupply,
    lastTon,
    lastJetton,
  } = props;
  const { tonPrice } = useContext(AppContext);

  const growth = useMemo(() => {
    return (calculateCurrentGrowth(totalSupply || 0, tonPrice || 0) * 100).toFixed(0)
  }, [totalSupply, tonPrice])

  const marketCap = useMemo(() => {
    if (tonPrice) {
      return calculateMarketCap(lastTon, lastJetton, tonPrice);
    }

    return 0;
  }, [
    tonPrice,
    lastTon,
    lastJetton,
  ]);
  const { pathname } = useLocation();

  return (
    <LinkButton to={to} className='user-card-container' external={false}>
      {pathname === '/home' ? (<img src={Hit} alt="hit" className='hit' />) : null}
      <div className="user-card">
        <div className="user-avatar" >
          {React.cloneElement(avatar, { width: '80px' })}
        </div>
        <div className="user-details">
          <div className="user-ticker">{name} [${ticker}]</div>
          <div className='user-market-cap'>Market Cap: ${marketCap.toFixed(2)}</div>
          <div className="user-creator">
            <span>Created by:</span>
            <div className="creator-avatar" >
              {React.cloneElement(creatorAvatar, { width: 16, height: 16 })}
              {creatorName}
            </div>
          </div>
        </div>
      </div>
      <div className='user-growth'>
        <div className='growth-note'>Current Growth</div>
        <div className='growth-number'>
          <span>{growth}%</span>
          <img src={UpIcon} height={26} alt="up icon" />
        </div>
      </div>
    </LinkButton>
  );
};

export default TokenCard;
