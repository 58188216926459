import service from './request';

export const getResult = async (uid: number, address: string) => {
  const res = await service<{
    "record": {
      "id": 1,
      "user_uid": 1949273964, "user_name": "pappleN", "reward": "1000 Points", "create time": 1725887294,
    }
    "remaining": 1
  }|"timeout">({
    method: "post",
    url: "/indexer/user/drawLottery",
    data: {
      "user_address": address,
      "user_uid": uid
    }
  })

  return res
}

export const getRemaining = async (uid: number, address?: string) =>{
   const res = await service({
    method:'post',
    url:'/indexer/user/drawLotteryRecord',
    data: {
      "user_address": address,
      "user_uid": uid
    }
  })
  return res
}