import { beginCell, Cell, TonClient, Transaction, storeMessage } from "@ton/ton";
import { Address, Dictionary } from "@ton/core";
import { sha256_sync } from "@ton/crypto";
import { sleep } from "@/lib";
import { getTonClient } from ".";

const CELL_MAX_SIZE_BYTES = Math.floor((1023 - 8) / 8);
const ONCHAIN_CONTENT_PREFIX = 0x00;
const SNAKE_PREFIX = 0x00;

function bufferToChunks(buff: Buffer, chunkSize: number) {
  const chunks: Buffer[] = [];
  while (buff.byteLength > 0) {
    chunks.push(buff.subarray(0, chunkSize));
    buff = buff.subarray(chunkSize);
  }
  return chunks;
}

export function makeSnakeCell(data: Buffer) {
  const chunks = bufferToChunks(data, CELL_MAX_SIZE_BYTES);
  const b = chunks.reduceRight((curCell, chunk, index) => {
    if (index === 0) {
      curCell.storeInt(SNAKE_PREFIX, 8);
    }
    curCell.storeBuffer(chunk);
    if (index > 0) {
      const cell = curCell.endCell();
      return beginCell().storeRef(cell);
    } else {
      return curCell;
    }
  }, beginCell());
  return b.endCell();
}

function readSnakeCell(cell: Cell): Buffer | null {
  let sl = cell.beginParse();
  if (sl.remainingBits < 8 || sl.loadUint(8) !== SNAKE_PREFIX) {
    return null;
  }
  let result = sl.loadBuffer(sl.remainingBits / 8);
  while (sl.remainingRefs) {
    const next = sl.loadRef().beginParse();
    result = Buffer.concat([result, next.loadBuffer(sl.remainingBits / 8)]);
    sl = next;
  }

  return result;
}

const toKey = (key: string) => {
  return BigInt(`0x${sha256_sync(key).toString("hex")}`);
};

export function readOnchainMetadata<T>(cell: Cell, keys: string[]) {
  const slice = cell.beginParse();
  const prefix = slice.loadUint(8);
  if (prefix !== ONCHAIN_CONTENT_PREFIX) {
    throw new Error("Unknown content");
  }

  const dict = Dictionary.load(
    Dictionary.Keys.BigUint(256),
    Dictionary.Values.Cell(),
    slice
  );

  return keys.reduce((acc, key) => {
    const value = dict.get(toKey(key));
    if (value) {
      const data = readSnakeCell(value);
      if (data) {
        acc[key] = data.toString("utf8");
      }
    }
    return acc;
  }, {} as Record<string, string>) as any as T;
}


export function buildOnchainMetadata(data: {
  name: string;
  description?: string;
  image: string;
  symbol?: string;
  decimals?: string;
  telegram?: string;
  twitter?: string;
  website?: string;
}): Cell {
  const dict = Dictionary.empty(
    Dictionary.Keys.BigUint(256),
    Dictionary.Values.Cell()
  );
  Object.entries(data).forEach(([key, value]) => {
    dict.set(toKey(key), makeSnakeCell(Buffer.from(value, "utf8")));
  });

  return beginCell()
    .storeInt(ONCHAIN_CONTENT_PREFIX, 8)
    .storeDict(dict)
    .endCell();
}

export async function waitForContractDeploy(address: Address, client: TonClient) {
  let isDeployed = false;
  let maxTries = 25;
  while (!isDeployed && maxTries > 0) {
    maxTries--;
    isDeployed = await client.isContractDeployed(address);
    if (isDeployed) return;
    await sleep(3000);
  }
  throw new Error("Timeout");
}

export const waitForTransaction = async (walletAddress: Address, boc: string, refetchInterval: number = 3000, refetchLimit: number = 30) => {
  const client = getTonClient();

  const hash = Cell.fromBase64(boc)
    .hash()
    .toString("base64");
  console.log('txHash:', hash);

  return new Promise<Transaction>((resolve,reject) => {
    let refetches = 0;
    const interval = setInterval(async () => {
      refetches += 1;
      console.log("waiting transaction...");

      const state = await client.getContractState(walletAddress);
      if (!state || !state.lastTransaction) {
        clearInterval(interval);
        reject("Transaction not valid");
        return
      }

      const { lt: lastLt, hash: lastHash } = state.lastTransaction;
      const lastTx = await client.getTransaction(walletAddress, lastLt, lastHash);

      if (lastTx && lastTx.inMessage) {
        const msgCell = beginCell().store(storeMessage(lastTx.inMessage)).endCell();
        const inMsgHash = msgCell.hash().toString("base64");
        console.log("InMsgHash", inMsgHash);

        if (inMsgHash === hash) {
          clearInterval(interval);
          resolve(lastTx);
        }
      }

      if (refetchLimit && refetches >= refetchLimit) {
        clearInterval(interval);
        reject("Transaction timeout");
      }
    }, refetchInterval);
  });
};

export const waitForNextTransaction = async (walletAddress: Address, hash: string, refetchInterval: number = 3000, refetchLimit: number = 30) => {
  const client = getTonClient();

  console.log('preHash:', hash);

  return new Promise<Transaction>((resolve, reject) => {
    let refetches = 0;
    const interval = setInterval(async () => {
      refetches += 1;
      console.log("check transaction...");

      const state = await client.getContractState(walletAddress);
      if (!state || !state.lastTransaction) {
        clearInterval(interval);
        reject("Transaction not valid");
        return
      }

      const { lt: lastLt, hash: lastHash } = state.lastTransaction;
      const lastTx = await client.getTransaction(walletAddress, lastLt, lastHash);

      console.log(lastTx?.prevTransactionHash.toString(16), lastTx?.prevTransactionLt);

      if (lastTx) {
        const pre = lastTx.prevTransactionHash.toString(16)
        console.log('last preHash:', pre);

        if (pre === hash) {
          clearInterval(interval);
          resolve(lastTx);
        }
      }

      if (refetchLimit && refetches >= refetchLimit) {
        clearInterval(interval);
        reject("Transaction timeout");
      }
    }, refetchInterval);
  });
};