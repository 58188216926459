import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Select } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'

import { FilterParams, TokenSort, TokenFilter, TokenOrder } from '@/lib/types';

export const TokenFilters = ({
  params,
  onSearch,
}: {
  params: FilterParams,
  onSearch: (data: FilterParams) => void,
}) => {

  const {
    register,
    handleSubmit,
    watch
  } = useForm<FilterParams>({
    defaultValues: params
  });

  const filterBy = watch('filterBy');
  const orderBy = watch('orderBy');
  const sortBy = watch('sortBy');
  const search = watch('search');

  useEffect(() => {
    onSearch({
      filterBy,
      orderBy,
      sortBy,
      search,
    });
  }, [
    filterBy,
    orderBy,
    sortBy,
  ])

  return <form onSubmit={handleSubmit(onSearch)}>
    <div className='w-full flex justify-stretch items-center gap-1'>
      <div className="w-full relative flex items-center gap-2">
        <Select
          className={clsx(
            'mt-3 block w-full appearance-none rounded-lg border-none bg-white/5 py-1.5 px-1.5 text-sm/6 text-white',
            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
            // Make the text of each option black on Windows
            '*:text-black'
          )}
          {...register('filterBy')}
        >
          <option value={TokenFilter.ALL}>All Tokens</option>
          <option value={TokenFilter.MY}>My Tokens</option>
        </Select>
        <ChevronDownIcon
          className="group pointer-events-none absolute top-5 right-2 size-4 fill-white/60"
          aria-hidden="true"
        />
      </div>

      <div className="w-full relative flex items-center gap-2">
        <Select
          className={clsx(
            'mt-3 block w-full appearance-none rounded-lg border-none bg-white/5 py-1.5 px-1.5 text-sm/6 text-white',
            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
            // Make the text of each option black on Windows
            '*:text-black'
          )}
          {...register('sortBy')}
        >
          <option value={TokenSort.ONE_DAY}>24 Hours Vol</option>
          <option value={TokenSort.LASTEST}>Lastest</option>
          <option value={TokenSort.MKTCAP}>Market cap</option>
        </Select>
        <ChevronDownIcon
          className="group pointer-events-none absolute top-5 right-2 size-4 fill-white/60"
          aria-hidden="true"
        />
      </div>

      <div className="w-full relative flex items-center gap-2">
        <Select
          className={clsx(
            'mt-3 block w-full appearance-none rounded-lg border-none bg-white/5 py-1.5 px-1.5 text-sm/6 text-white',
            'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
            // Make the text of each option black on Windows
            '*:text-black'
          )}
          {...register('orderBy')}
        >
          <option value={TokenOrder.DESC}>Desc</option>
          <option value={TokenOrder.ASC}>Asc</option>
        </Select>
        <ChevronDownIcon
          className="group pointer-events-none absolute top-5 right-2 size-4 fill-white/60"
          aria-hidden="true"
        />
      </div>
    </div>
    <div className="my-2 w-full gap-0 flex">
      <input type="text"
        placeholder="Search"
        className="w-full bg-gray-800 text-gray-400 placeholder-gray-500 px-2 py-1 rounded-l-md focus:outline-none"
        {...register('search')}
      />
      <button type='submit'
        className="bg-[#0297E8] text-black px-4 py-1 rounded-r-md hover:bg-blue-[#0297E8]"
      >
        SEARCH
      </button>
    </div>

  </form>
}
