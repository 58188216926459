import {
  forwardRef,
  HTMLAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  Suspense,
  lazy
} from "react"

// import spinTon from "@/assets/spinTon.png"
// import spinPoint from "@/assets/spinPoint.png"
// import SpinThank from "@/assets/SpinThank.png"

import spinTon from "@/assets/TON.svg"
import spinPoint from "@/assets/Point.svg"
import SpinThank from "@/assets/heart.svg"

import ton1 from "@/assets/1ton.png"
import point1 from "@/assets/1point.png"
import point100 from "@/assets/100point.png"
import ton100 from "@/assets/100ton.png"
import ton10 from "@/assets/10ton.png"
import point10 from "@/assets/10point.png"
import thanks from "@/assets/thanks.png"
import stars from "@/assets/stars.png"

const ImageComponent = lazy(() => import('@/components/common/ImageComponent'))

const step = 50
const range = 5
const circle = 3
const minSpeed = 100

export const rewardMap: Record<string, number | number[]> = {
  "10 TON": 1,
  "1000 Points": 2,
  'Thanks': [3, 4, 8],
  "100 TON": 5,
  "5000 Points": 6,
  "10000 Points": 7,
  "1 TON": 9,
}

export type SpinRef = {
  drawTo: (rewardId: number) => Promise<void>
  drawPending: () => void
}
enum SpinType {
  ton = 1,
  point,
  thank,
}

export const Spin = forwardRef<SpinRef, HTMLAttributes<HTMLDivElement>>(
  function _spin({ className, ...props }, ref) {
    const list: Array<{
      id: number
      type: SpinType
      value: number
    }> = [
        {
          id: 1,
          type: SpinType.ton,
          value: 10,
        },
        {
          id: 2,
          type: SpinType.point,
          value: 1000,
        },
        {
          id: 3,
          type: SpinType.thank,
          value: 0,
        },
        {
          id: 4,
          type: SpinType.thank,
          value: 0,
        },
        {
          id: 5,
          type: SpinType.ton,
          value: 100,
        },
        {
          id: 6,
          type: SpinType.point,
          value: 5000,
        },
        {
          id: 7,
          type: SpinType.point,
          value: 10000,
        },
        {
          id: 8,
          type: SpinType.thank,
          value: 0,
        },
        {
          id: 9,
          type: SpinType.ton,
          value: 1,
        },
      ]
    const classN = useMemo(
      () => `grid grid-cols-3 gap-4 px-2 ${className}`,
      [className]
    )
    const [value, setValue] = useState(0)


    const [show, setShow] = useState(false)
    const drawAnimate = (maxTime: number, time: number, callBack?: () => void) => {
      const s = Math.max(
        minSpeed,
        time < range
          ? time * step
          : maxTime - time > range
            ? range * step
            : Math.abs(maxTime - time - range) * 2 * step + range * step
      )

      setTimeout(() => {
        if (time === maxTime) {
          callBack?.()
          return
        } else {
          setValue((time + 1) % list.length)
          drawAnimate(maxTime, time + 1, callBack)
        }
      }, s)
    }


    const timer = useRef<NodeJS.Timeout | null>(null)
    const drawPending = useCallback(() => {
      let time = value
      let maxTime = time + 1
      setValue(time % list.length)
      timer.current = setInterval(() => {
        if (time === maxTime) {
          return
        } else {
          time = time + 1
          maxTime = time + 1
          setValue((time + 1) % list.length)
        }
      }, minSpeed);
    }, [value])

    const drawTo = useCallback((rewardId: number) => {
      return new Promise<void>((resolve, reject) => {
        console.log(rewardId);
        if (timer.current) {
          clearInterval(timer.current)
          timer.current = null
          
        const _index = list.findIndex((item) => item.id === rewardId)
        console.log(_index);
        const index = _index < 0 ? 3 : _index
        const maxTime = index - 0 + 9 * circle
        const init = value
        setValue(init % list.length)
        drawAnimate(maxTime, init, () => {
          setShow(true)
          resolve()
        })
        }
      })

    }, [value])
    useImperativeHandle(ref, () => ({
      drawTo, drawPending
    }))
    return (
      <div className={classN} {...props}>
        {list.map((item, index) => (
          <SpinItem
            key={`${item.type}${index}`}
            type={item.type}
            value={item.value}
            active={value === index}
          />
        ))}
        {show && <ResultModal reward={
          list[value]} dismiss={
            () => {
              setShow(false)
            }
          } />}
      </div>
    )
  }
)

const textMap = {
  [SpinType.ton]: "TON",
  [SpinType.point]: "points",
  [SpinType.thank]: "missed",
}
const SpinItem = ({
  type,
  active,
  value,
}: {
  type: SpinType
  active: boolean
  value: number
}) => {

  // const imageMap = {
  //   [SpinType.ton]: spinTon,
  //   [SpinType.point]: spinPoint,
  //   [SpinType.thank]: SpinThank,
  // }
  const imageMap = {
    [SpinType.ton]: '/images/TON.svg',
    [SpinType.point]: '/images/Point.svg',
    [SpinType.thank]: '/images/heart.svg',
  }
  const bgColor = useMemo(
    () =>
      active ? "from-yellow-100 to-yellow-600" : "from-[#fff] to-blue-100",
    [active]
  )

  const itemEl = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  function calWidth() {
    if (itemEl.current) {
      const width = itemEl.current.clientWidth

      setHeight(width)
    }
  }
  useEffect(() => {
    calWidth()
    window.addEventListener("resize", calWidth)
    return () => {
      window.removeEventListener("resize", calWidth)
    }
  }, [itemEl])
  return (
    <div
      ref={itemEl}
      className='bg-gradient-to-b from-green-500 to-blue-600 p-1 rounded-2xl'
      style={{
        height,
      }}
    >
      <div
        className={
          "bg-gradient-to-b  p-1 rounded-xl flex flex-col items-center h-full " +
          `${bgColor}`
        }
      >
        <div className='flex-1 h-1/2'>
          <Suspense fallback={<span>loading...</span>}>
            <ImageComponent url={imageMap[type]} classType='h-full w-auto'/>
          </Suspense>
          {/* <img src={imageMap[type]} className='h-full w-auto' /> */}
        </div>
        <div className='w-full text-font-400 font-bold text-xs sm:text-base flex flex-col items-center justify-center min-h-8 sm:min-h-12 '>
          {Boolean(value) && <p className='w-auto '>{value.toLocaleString()}</p>}
          <p className='w-auto'>{textMap[type].toUpperCase()}</p>
        </div>
      </div>
    </div>
  )
}


const ResultModal = ({ reward, dismiss }: {
  reward: {
    id: number,
    type: SpinType,
    value: number,
  },
  dismiss: () => void
}) => {
  const imageMap: Record<number, string> = {
    1: ton10,
    2: point1, 3: thanks, 4: thanks, 5: ton100, 6: point10, 7: point100, 8: thanks, 9: ton1
  }
  return <div className="fixed bg-gray-800/60 top-0 left-0 bottom-0 right-0 z-10 h-full flex flex-col   items-center">
    <div className="w-full overflow-hidden relative  h-1/4"><svg style={{
      left: "-20%"
    }} className="absolute top-0  right-0 w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 270 201" fill="none">
      <path d="M98.1534 -33.9209L269.969 199.413H66.861L63.6106 200.054L-116.679 -16.1761L98.1534 -33.9209Z" fill="url(#paint0_linear_1168_885)" />
      <defs>
        <linearGradient id="paint0_linear_1168_885" x1="54.0597" y1="120.402" x2="29.1015" y2="194.309" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0297E8" stopOpacity="0" />
          <stop offset="1" stopColor="#0297E8" stopOpacity="0.45" />
        </linearGradient>
      </defs>
    </svg>
      <svg style={{
        left: "-10%"
      }} className="absolute top-0   right-0 w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 332 201" fill="none">
        <path d="M331.324 200.054H124.521L-55.3243 -15.5345L159.508 -33.2793L331.324 200.054Z" fill="url(#paint0_linear_1168_886)" />
        <defs>
          <linearGradient id="paint0_linear_1168_886" x1="120.383" y1="110.855" x2="88.6556" y2="193.566" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0297E8" stopOpacity="0" />
            <stop offset="1" stopColor="#0297E8" stopOpacity="0.5" />
          </linearGradient>
        </defs>
      </svg>
      <svg style={{
        left: "10%"
      }} className="absolute top-0   right-0 w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333 200" fill="none">
        <path d="M0.754303 199.413H207.557L387.402 -16.1761L172.57 -33.9209L0.754303 199.413Z" fill="url(#paint0_linear_1168_889)" />
        <defs>
          <linearGradient id="paint0_linear_1168_889" x1="211.695" y1="110.213" x2="243.422" y2="192.925" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0297E8" stopOpacity="0" />
            <stop offset="1" stopColor="#0297E8" stopOpacity="0.5" />
          </linearGradient>
        </defs>
      </svg> <svg style={{
        left: "20%"
      }} className="absolute top-0   right-0 w-full h-full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 271 201" fill="none">
        <path d="M171.925 -33.9209L0.108856 199.413H203.217L206.467 200.054L386.757 -16.1761L171.925 -33.9209Z" fill="url(#paint0_linear_1168_888)" />
        <defs>
          <linearGradient id="paint0_linear_1168_888" x1="216.018" y1="120.402" x2="240.977" y2="194.309" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0297E8" stopOpacity="0" />
            <stop offset="1" stopColor="#0297E8" stopOpacity="0.45" />
          </linearGradient>
        </defs>
      </svg>
    </div>


    <div
      style={{
        borderImage: "linear-gradient(to bottom, #39FF8D,#0061D4) 1"
      }}
      // className='bg-gradient-to-b from-green-500 to-blue-600 p-1 rounded-2xl h-3/6 w-4/6'
      className='border-green-500 border-2 w-5/6 relative'
    >  <img style={{
      top: '0',
      left: '50%',
      transform: "translate(-50%,-120%)"
    }} src={stars
    } className="absolute " />
      <img style={{
        top: '-20%',
        left: '50%',
        transform: "translateX(-50%)"
      }} src={
        imageMap[reward.id]
      } className="absolute " />
      <div className="bg-black/80 w-full pt-16 pb-12 flex flex-col justify-center items-center text-primary-100 font-bold px-5">
        {
          reward.type === SpinType.thank ? <div className="flex  text-3xl mb-6">
            MISSED
          </div> : <> <p>
            CONGRATULATIONS
          </p>
            <div className="flex  text-3xl mb-6">
              {Boolean(reward.value) && <p className='w-auto mr-1'>{reward.value}</p>}
              <p className='w-auto'>{textMap[reward.type].toUpperCase()}</p>
            </div>
          </>
        }

        <button style={{
          border: "1px solid rgba(2, 151, 232, 0.5)"
        }} className="px-8 py-2 text-dark-700 rounded mb-3" onClick={() => dismiss()}>
          CONFIRM
        </button>
        <p className="text-dark-700 text-xs text-center">
          * Prizes will be awarded the following day at 12:00 (UTC+00:00)
        </p>
      </div>
    </div>

  </div>
}