import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Address } from '@ton/ton';
import { TonConnectUI } from '@tonconnect/ui-react';
import styled from '@emotion/styled';
import { buyToken, swapTokenWithTon } from '@/contract';

import './index.less';

const BuyButtonInner = styled(Button)({
  color: 'white',
  textAlign: 'center',
  fontSize: '18px',
  width: '100%',
  backgroundColor: '#50a275',
  '&:hover': {
    backgroundColor: '#50a275'
  }
});

export function BuyButton({
  tonConnectUI,
  jettonAddress,
  amount,
  balance,
  setAmount,
  isDedust,
  refresh,
}: {
  tonConnectUI: TonConnectUI | null,
  jettonAddress: Address,
  amount: number,
  balance: number;
  setAmount: (amount: string) => void,
  isDedust: boolean,
  refresh: () => void,
}) {
  const [isDisabled, setDisabled] = useState(false);

  const handleBuy = async () => {

    if (!tonConnectUI || !tonConnectUI.account) {
      toast.error("You need to connect wallet");
      return;
    }

    if (balance < amount) {
      toast.error("Your balance is not enough");
      return;
    }

    toast.loading("Please wait while buy tokens...", {
      id: "pumphub"
    });
    setDisabled(true);

    try {
      if (isDedust) {
        await swapTokenWithTon(tonConnectUI, jettonAddress, amount);
      }
      else {
        await buyToken(tonConnectUI, jettonAddress, amount);
      }

      toast.success("Buy tokens successfully", {
        id: "pumphub"
      });

      refresh();
      setAmount("0");
    }
    catch (ex) {
      console.log(ex);
      toast.error("Buy tokens failed", {
        id: "pumphub"
      });
    }

    setDisabled(false);
  }


  return <div className='buy-container'>
    <BuyButtonInner onClick={handleBuy} disabled={isDisabled}>
      {
        isDedust ? 'Buy on DeDust' : 'Buy'
      }
    </BuyButtonInner>
  </div >
}
