import { IconButton } from '@mui/material'
import toast from 'react-hot-toast';
import shareIcon from '@/assets/share.png'
import { APP_URL } from '@/components/App';
import { generateLink } from '@/lib/generateShareLink';
import { useContext } from 'react';
import { AppContext } from '@/context';

export const ShareLink = ({
  address
}: {
  address: string
}) => {
  const { userProfile } = useContext(AppContext);

  const onClick = () => {
    const link = generateLink(APP_URL, {
      path: 'trading',
      jetton: address,
      referrer: userProfile?.user_uid ?? 0
    });
    navigator.clipboard.writeText(link);
    toast.success('The link has been copied to the clipboard');
  };

  return (
    <IconButton onClick={onClick} aria-label="share" color="primary">
      <img src={shareIcon} height={28} width={28} />
    </IconButton>
  )
}
