import { fetchLeaderboard } from "@/api/jettons"
import { shortenAddress } from "@/lib"
import { JettonToken, LeaderboardResponse, UserRank } from "@/lib/types"
import { useEffect, useState, useContext } from "react"
import { Loading } from "../common/Loading"
import { AppContext } from '@/context';
export const LeaderboardTab = () => {

  const [loading, setLoading] = useState(false);
  const [ranks, setRanks] = useState<UserRank[]>([]);

  // 测试
  const { userProfile, isLoading } = useContext(AppContext)

  useEffect(() => {
    loadLeaderboard();
  }, [])

  const loadLeaderboard = async () => {

    setLoading(true);

    try {
      const resp = await fetchLeaderboard();
      const data = resp.data as LeaderboardResponse;
      // const basePoints = localStorage.getItem('basePoint')
      // 本地测试加入basePoints
      // const testRank = data.ranks
      // testRank.forEach(rank => {
      //   if (rank.uid == userProfile?.user_uid) {
      //     const pointsToAdd = Number(basePoints) || 0
      //     // 将 trading_point 转换为数字，添加 600，然后转换回字符串
      //     const point = Number(rank.trading_point) + pointsToAdd
      //     rank.trading_point = point.toFixed(2);
      //     console.log(rank.trading_point, 'rank.trading_point');
      //   }
      // })
      // testRank.sort((a, b) => {
      //   return parseFloat(b.trading_point) - parseFloat(a.trading_point);
      // })
      // console.log(testRank, 'testRank');
      // // 测试 
      // setRanks(testRank);
      setRanks(data.ranks);
    }
    catch {
      setRanks([]);
    }

    setLoading(false);
  }

  return (
    <div className='w-full text-center px-2'>

      <div className="w-full flex flex-col gap-6">

        <div className="w-full flex gap-4 justify-between">
          <span className="text-dark-700 text-sm text-left py-2 w-12">
            Ranking
          </span>
          <span className="text-dark-700 text-sm text-center px-2 py-2 w-32">
            User
          </span>
          <span className="text-dark-700 text-sm text-center py-2 w-24">
            Points
          </span>
        </div>

        {
          loading ? <div className="w-full">
            <Loading />
          </div>
            : <>
              {
                ranks.map((user, idx) =>
                  <div key={`leaderboard-${user.uid}`}
                    className="w-full flex gap-2 items-center content-stretch">
                    <div className="text-sm text-primary-100 w-8">
                      <span className="text-center">
                        {idx + 1}
                      </span>
                    </div>
                    <div className="w-48 text-sm text-white flex items-center gap-2">
                      <img src={user.image != "" ? user.image : "/images/ic_ranked_user.png"} alt="User" className="w-10 h-10 rounded-full" />
                      <span className="truncate break-all">
                        {user.name != "" ? user.name : shortenAddress(user.address)}
                      </span>
                    </div>
                    <div className="text-sm text-success-200 w-16">
                      <span className="text-center">
                        {user.trading_point.toLocaleString()}
                      </span>
                    </div>
                  </div>)
              }
            </>
        }

      </div>
    </div>
  )
}