import { useContext, useEffect, useState } from "react";

import { fetchHighestGrowthTokens } from "@/api/jettons";
import { AppContext } from "@/context";
import { formatBN } from "@/lib/format";
import { shortenAddress } from "@/lib";
import { JettonToken } from "@/lib/types";
import { Loading } from "@/components/common/Loading";

import TokenCard from "@/components/common/TokenCard";

import './index.less';

export function HomeUserCard() {
  const { walletAddress: address } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [highestGrowth, setHighestGrowth] = useState<JettonToken>({} as JettonToken);

  useEffect(() => {
    const fetchHighestGrowth = async () => {
      try {
        setLoading(true);
        const res = await fetchHighestGrowthTokens();
        if (res.data) {
          setHighestGrowth(res?.data[0] || {});
        }
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false);
      }
    }
    fetchHighestGrowth();
  }, [address])


  if (loading) {
    return <Loading />
  }

  const highestGrowthTokenAddress = highestGrowth?.jetton_master_address

  return (
    <div className="home-user-card">
      <TokenCard
        to={`/trading/${highestGrowthTokenAddress}`}
        avatar={<img src={highestGrowth?.jetton_image} alt={'avatar'} />}
        name={highestGrowth?.jetton_name}
        ticker={highestGrowth?.jetton_symbol}
        totalSupply={formatBN(highestGrowth?.total_supply)}
        lastTon={formatBN(highestGrowth?.ton_amount)}
        lastJetton={formatBN(highestGrowth?.jetton_amount)}
        creatorName={highestGrowth?.creator_name != "" ? highestGrowth?.creator_name : shortenAddress(highestGrowth?.creator_address)}
        creatorAvatar={<img height={32} src={highestGrowth?.creator_image != "" ? highestGrowth?.creator_image : '/images/ic_avatar.png'} alt={'creator_image'} />}
      />
    </div>
  )
}
