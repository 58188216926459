import { fromNano } from "@ton/ton";
import BigNumber from "bignumber.js";

// val supply
export const formatBN = (val: string, decimals: number = 9): number => {
    const bigNumber = new BigNumber(val);
    const tokenDecimal = new BigNumber(Math.pow(10, decimals));

    // Calculate the raw number
    const rawNumber = bigNumber.div(tokenDecimal);

    // Return the raw number as a string to maintain precision
    return rawNumber.toNumber();
}

export const formatNumber = (val: string, precision: number = 2): string => {
    const amount = parseFloat(fromNano(val));
    return Number(amount.toFixed(precision)).toString();
}

export const prettyNumber = (val: string, precision: number = 2): string => {
    const value = formatBN(val);

    if (value >= 1_000_000) {
        // Format for millions
        return (value / 1_000_000).toFixed(0) + 'M';
    } else if (value >= 1_000) {
        // Format for thousands
        return (value / 1_000).toFixed(0) + 'K';
    } else {
        // Format for smaller numbers
        return value.toFixed(precision).toString();
    }
}