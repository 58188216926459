// export const API_URL = import.meta.env.API_URL ?? "";
import 'dotenv/config';

import { Address } from "@ton/ton";

export const globalData: {
  initDataRaw: string | undefined,
  tonEndpoint: string | undefined,
  referrer: number | undefined,
} = {
  initDataRaw: undefined,
  tonEndpoint: undefined,
  referrer: undefined,
};

export const API_URL = import.meta.env.VITE_API_URL || ""
export const TON_NETWORK = import.meta.env.VITE_TON_NETWORK || "mainnet";
export const TON_VIEWER = import.meta.env.VITE_TON_VIEWER || "";

export const CLOUDFLARE_ACCOUNT_ID = import.meta.env.VITE_CLOUDFLARE_ACCOUNT_ID || ""
export const CLOUDFLARE_ACCESS_ID = import.meta.env.VITE_CLOUDFLARE_ACCESS_ID || ""
export const CLOUDFLARE_BUCKET = import.meta.env.VITE_CLOUDFLARE_BUCKET || ""
export const CLOUDFLARE_ACCESS_KEY = import.meta.env.VITE_CLOUDFLARE_ACCESS_KEY || ""
export const CLOUDFLARE_PUBLIC_URL = import.meta.env.VITE_CLOUDFLARE_PUBLIC_URL || ""

export const DEDUST_ADDRESS = Address.parse(import.meta.env.VITE_DEDUST_ADDRESS || "");

export const OWNER_ADDRESS = Address.parse(import.meta.env.VITE_OWNER_ADDRESS || "");

export const BONDING_CURVE = 800_000_000;
export const MAX_SUPPLY = 1_064_000_000;
export const K = 351_120_000_000;
export const INIT_TON = 330;


export const TG_LINK = 'https://t.me/pumphubnews';
export const TG_CHAT_LINK = 'https://t.me/pumphubpro';
export const TWITTER_LINK = 'https://x.com/pumphubpro';
export const HOW_IT_WORKS_LINK = 'https://telegra.ph/%F0%9D%90%87%F0%9D%90%A8%F0%9D%90%B0-%F0%9D%90%A2%F0%9D%90%AD-%F0%9D%90%B0%F0%9D%90%A8%F0%9D%90%AB%F0%9D%90%A4%F0%9D%90%AC-07-14'