import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { TonConnectUI } from '@tonconnect/ui-react';
import styled from '@emotion/styled';
import { Button } from '@mui/material';

import { sellToken, swapTokenWithJetton, swapTokenWithTon } from '@/contract';
import { Address } from '@ton/ton';

import './index.less';

const SellButtonInner = styled(Button)({
  color: 'white',
  textAlign: 'center',
  fontSize: '18px',
  width: '100%',
  backgroundColor: '#E25463',
  '&:hover': {
    backgroundColor: '#E25463'
  }
});

export function SellButton({
  tonConnectUI,
  jettonAddress,
  amount,
  balance,
  setAmount,
  isDedust,
  refresh,
}: {
  tonConnectUI: TonConnectUI | null,
  jettonAddress: Address,
  amount: number,
  balance: number;
  setAmount: (amount: string) => void,
  isDedust: boolean,
  refresh: () => void,
}) {
  const [isDisabled, setDisabled] = useState(false);

  const handleSell = async () => {

    if (!tonConnectUI || !tonConnectUI.account) {
      toast.error("You need to connect wallet");
      return;
    }

    if (balance < amount) {
      toast.error("Your balance is not enough");
      return;
    }

    toast.loading("Please wait while sell token...", {
      id: "pumphub"
    });
    setDisabled(true);

    try {
      if (isDedust) {
        await swapTokenWithJetton(tonConnectUI, jettonAddress, amount);
      }
      else {
        await sellToken(tonConnectUI, jettonAddress, amount);
      }

      toast.success("Your token has been sold successfully", {
        id: "pumphub"
      });

      refresh();
      setAmount("0");
    }
    catch (ex) {
      console.log(ex);
      toast.error("Token sell failed", {
        id: "pumphub"
      });
    }

    setDisabled(false);
  }

  return <div className='sell-container'>
    <SellButtonInner onClick={handleSell} disabled={isDisabled}>
      {
        isDedust ? 'Sell on DeDust' : 'Sell'
      }
    </SellButtonInner>
  </div>
}

