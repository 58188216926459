import { API_URL } from "@/lib/constants";
import request from './request';

export const fetchUserJettons = async (uid: number, jetton_master_address: string = "") => {
  return await request.post(`${API_URL}/indexer/user/jetton/${uid}`, {
    jetton_master_address,
  });
}

export const fetchUserInfo = async (uid: number) => {
  return await request.post(`${API_URL}/indexer/user/info/${uid}`);
}

export const createUser = async (
  params: {
    user_address?: string,
    user_uid?: number,
    user_username?: string,
    user_name?: string,
    user_image?: string,
    is_premium?: number,
    referrer?: number,
  }
) => {
  return await request.post(`${API_URL}/indexer/user/create`, params)
}

export const isPremium = async (uid:number) => {
  return await request.post(`${API_URL}/indexer/user/premium/${uid}`)
}

export const registerTime = async (uid:number):Promise<string> => {
  return await request.post(`${API_URL}/indexer/user/age/${uid}`)
}