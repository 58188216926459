import { HOW_IT_WORKS_LINK, TG_CHAT_LINK, TG_LINK, TWITTER_LINK } from '@/lib/constants';
import { Link } from 'react-router-dom';

export function HomeLinks() {
  const links = [
    { linkClassName: 'telegram', link: TG_LINK, text: 'News' },
    { linkClassName: 'telegram', link: TG_CHAT_LINK, text: 'Chatroom' },
    { linkClassName: 'twitter', link: TWITTER_LINK, text: 'Twitter' },
    { linkClassName: 'how-it-works', link: HOW_IT_WORKS_LINK, text: 'How it works' },
  ];

  return (
    <div className="flex justify-between mb-3">
      {links.map(({ link, text }) => {
        return (
          <Link to={link} target="_blank" key={text}>
            <span className="text-left text-gray-400 text-sm underline p-0">
              {text}
            </span>
          </Link>
        );
      })}
    </div>
  );
}
