import { useState } from 'react';
import KLineChart from '@/components/Trading/KLineChart';
import { HoldersPanel } from '../HoldersPanel';
import { JettonToken } from '@/lib/types';

import './index.less';

enum TradingHistoryTab {
  kLineChart = 'kLineChart',
  holderDistribution = 'holderDistribution',
}

export const TradingHistory = ({ address, tokenInfo }: { address: string, tokenInfo: JettonToken }) => {
  const [activeTab, setActiveTab] = useState(TradingHistoryTab.kLineChart);

  return (
    <div className='trading-chart'>
      <div className="switch-view-container">
        <div
          className={`switch-view ${activeTab === 'kLineChart' ? 'active' : ''}`}
          onClick={() => setActiveTab(TradingHistoryTab.kLineChart)}
        >
          K line chart
        </div>
        <div
          className={`switch-view ${activeTab === 'holderDistribution' ? 'active' : ''}`}
          onClick={() => setActiveTab(TradingHistoryTab.holderDistribution)}
        >
          Holder distribution
        </div>
      </div>

      {
        activeTab == 'kLineChart' ?
          <KLineChart jettonAddress={tokenInfo.jetton_master_address} />
          : <HoldersPanel tokenInfo={tokenInfo} address={address} />
      }
    </div>
  );
};

