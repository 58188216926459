import React from 'react';
import './mockEnv.ts';
import ReactDOM from 'react-dom/client';
import { Root } from '@/components/Root';

import { createTheme, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { red } from '@mui/material/colors';
import { Toaster } from 'react-hot-toast';
import { AppContextProvider } from '@/context';

// Uncomment this import in case, you would like to develop the application even outside
// the Telegram application, just in your browser.

import '@telegram-apps/telegram-ui/dist/styles.css';
import './global.css';

// A custom theme for this app
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#4098e4',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <CssBaseline />
        <Root />

        <Toaster
          position="top-center"
          reverseOrder={false}
        />
      </AppContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);
