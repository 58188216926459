import { calculateBasePoint, calculateBoostBase, calculateFarmBoost, calculateTradingPoint, shortenAddress,calculateShareBoost } from "@/lib"
import { generateLink } from "@/lib/generateShareLink";
import { APP_URL } from "../App";
import toast from "react-hot-toast";
import { useContext, useMemo } from "react";
import { AppContext } from "@/context";
import { formatBN } from "@/lib/format";

import { ClipboardIcon } from '@heroicons/react/20/solid'

export const UserInfo = ({
  address,
  userid,
  username,
}: {
  address: string | undefined,
  userid: number | undefined,
  username: string | undefined,
}) => {
  const { userProfile } = useContext(AppContext);

  const tradingPoints = useMemo(() => {
    if (userProfile) {
      const { jetton_created, jetton_buy_volume, jetton_sell_volume, jetton_completed, referrals_amount,base_point,farm_boost } = userProfile;
      const buyVolume = formatBN(jetton_buy_volume);
      const sellVolume = formatBN(jetton_sell_volume);
      const boostBase = calculateBoostBase(jetton_created, buyVolume);
      const basePoint = calculateBasePoint(buyVolume, sellVolume);
      const farmBoost = calculateFarmBoost(boostBase, jetton_completed);
      const sharePoint = calculateShareBoost(base_point,farm_boost)
      // 测试积分
      // const basePoints = Number(localStorage.getItem('basePoint'))
      return calculateTradingPoint(basePoint, farmBoost, referrals_amount, sharePoint);
    }
    else {
      return 0;
    }
  }, [userProfile]);

  const link = generateLink(APP_URL, {
    path: "",
    jetton: "",
    referrer: userid ?? 0,
  });
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('The link has been copied to the clipboard');
    }).catch(_ => {
      toast.error('Failed to copy the link');
    });
  };

  return (
    <div className='bg-dark-300/60 rounded-md px-2 py-5 opacity-80 flex flex-col gap-3 text-xs mb-8'>

      <div className='w-full flex justify-between items-center gap-2'>
        <span className='text-white flex-1'>Connected wallet:</span>
        <span className='text-primary-400 truncate'>{shortenAddress(address ?? "-")}</span>
        <button type='button' onClick={() => handleCopy(address ?? "")}>
          <ClipboardIcon color="#ff41cf" className="w-4 h-4" />
        </button>
      </div>

      <div className='w-full h-0.5 border border-dark-400 border-dashed' />

      <div className='w-full flex justify-between gap-4'>
        <span className='text-white'>Username:</span>
        <span className='text-primary-400 truncate'>{username ?? "-"}</span>
      </div>

      <div className='w-full h-0.5 border border-dark-400 border-dashed' />

      <div className='w-full flex justify-between gap-4'>
        <span className='text-white'>Trading points:</span>
        <span className='text-primary-50 text-md truncate'>{tradingPoints.toFixed(1)} points</span>
      </div>

      <div className='w-full h-0.5 border border-dark-400 border-dashed' />

      <div className='w-full flex justify-between items-center gap-2'>
        <span className='text-white'>Referral link:</span>
        <span className='text-primary-50 flex-1 truncate'>{link ?? "-"}</span>
        <button type='button' onClick={() => handleCopy(link)}>
          <ClipboardIcon color="#00c1ff" className="w-4 h-4" />
        </button>
      </div>

    </div>
  )
}