import React, { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { uploadFile } from '@/lib/cloudflare';
import { v4 } from 'uuid';

export const ImageUploader = ({
  avatar,
  setAvatar,
}: {
  avatar: string | undefined,
  setAvatar: (_avatar: string) => void;
}) => {
  const uploadRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {

    toast.loading("Please wait while upload image...", {
      id: "pumphub"
    });

    try {
      const file = e.target.files ? e.target.files[0] : null;
      if (file) {
        const fileId = `${v4()}.jpg`;

        const reader = new FileReader();

        reader.onload = function (e) {

          const img = new Image();
          img.onload = function () {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (ctx) {
              // Set canvas dimensions to image dimensions
              canvas.width = Math.min(img.width, 200);
              canvas.height = Math.min(img.height, 200);

              // Draw image on canvas
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

              // Compress the image
              const quality = 0.8; // Adjust the quality (0.0 to 1.0)
              canvas.toBlob(async (data) => {
                if (data) {
                  const bytes = await data.arrayBuffer();
                  const buffer = new Uint8Array(bytes);

                  const uri = await uploadFile(buffer, fileId);
                  setAvatar(uri);

                  toast.dismiss("pumphub");
                }
              }, 'image/jpeg', quality);

            }
          };

          if (img && e.target && e.target.result) {
            img.src = e.target.result as string;
          }
        };

        reader.readAsDataURL(file);
      }
    }
    catch (ex) {
      console.log(ex);
      toast.error("Get error while upload image", {
        id: "pumphub"
      });
    }
  };

  return (

    <div className='relative'>

      <input
        id="avatar-upload"
        type="file"
        accept="image/*"
        className='hidden'
        ref={uploadRef}
        onChange={handleImageUpload}
      />

      {
        avatar ?
          <img src={avatar} alt="Avatar" className='w-24 h-24 rounded-full border border-dark-200' />
          :
          <button type='button'
            className='bg-dark-200 rounded-full p-6'
            onClick={() => {
              uploadRef.current?.click();
            }}>
            <img src='/images/ic_camera.svg' alt='Upload' className='w-10 h-10' />
          </button>
      }

    </div>

  );
};