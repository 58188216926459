import { useIntegration } from '@telegram-apps/react-router-integration';
import { getHttpEndpoint } from "@orbs-network/ton-access";
import { TON_NETWORK } from "@/lib/constants";
import {
  useViewport,
  useThemeParams,
  useMiniApp,
  bindMiniAppCSSVars,
  bindThemeParamsCSSVars,
  bindViewportCSSVars,
  initNavigator,
  retrieveLaunchParams,
  useLaunchParams,
  initSwipeBehavior,
  postEvent,
} from '@telegram-apps/sdk-react';
import { AppRoot } from '@telegram-apps/telegram-ui';
import { type FC, useEffect, useMemo } from 'react';
import {
  Route,
  Router,
  Routes,
} from 'react-router-dom';
import { globalData } from '@/lib/constants';

import { routes } from '@/routes/routes';
import { Login } from './Pages/Login';
import { Claim } from './Pages/Claim';
import { Check } from './Pages/Check'
import { Rising } from './Pages/Rising'
import { Share } from './Pages/Share';
import { Layout } from './Layout';
import { URLArgs } from '@/lib/types';


export const BOT_NAME = import.meta.env.VITE_BOT_NAME;
export const APP_NAME = import.meta.env.VITE_APP_NAME;
export const APP_URL = `https://t.me/${BOT_NAME}/${APP_NAME}`;

export const App: FC = () => {
  const lp = useLaunchParams();
  const { initDataRaw, initData } = retrieveLaunchParams();
  const [swipeBehavior] = initSwipeBehavior()

  const miniApp = useMiniApp();
  const themeParams = useThemeParams();
  const viewport = useViewport();

  postEvent('web_app_expand')
  useEffect(() => {
    if (Number(lp.version) >= 7.7) {
      swipeBehavior.disableVerticalSwipe();
    }
  }, []);

  useEffect(() => {
    // store the initial data in the globalData object
    globalData.initDataRaw = initDataRaw;
  }, [initDataRaw]);


  useEffect(() => {
    (async () => {
      const endpoint = await getHttpEndpoint({
        network: TON_NETWORK
      });
      console.log(endpoint)
      globalData.tonEndpoint = endpoint;
    })();

  }, []);

  useEffect(() => {
    return bindMiniAppCSSVars(miniApp, themeParams);
  }, [miniApp, themeParams]);

  useEffect(() => {
    return bindThemeParamsCSSVars(themeParams);
  }, [themeParams]);

  useEffect(() => {
    return viewport && bindViewportCSSVars(viewport);
  }, [viewport]);

  // Create new application navigator and attach it to the browser history, so it could modify
  // it and listen to its changes.
  const navigator = useMemo(() => initNavigator('app-navigation-state', {
    hashMode: import.meta.env.DEV ? null : 'classic'
  }), []);
  const [location, reactNavigator] = useIntegration(navigator);

  // Don't forget to attach the navigator to allow it to control the BackButton state as well
  // as browser history.
  useEffect(() => {
    navigator.attach();
    return () => navigator.detach();
  }, [navigator]);

  // relative generateLink function
  useEffect(() => {
    const startParam = initData?.startParam
    console.log(startParam, 'startParam');
    if (startParam && startParam != 'debug') {
      const query = Buffer.from(startParam, "base64").toString("utf-8");
      const { path, jetton, referrer } = JSON.parse(query) as URLArgs;

      if (referrer) {
        globalData.referrer = referrer;
      }

      if (path) {
        navigator.replace(`/${path}/${jetton}`)
      }

    }
  }, [navigator])

  return (
    <AppRoot
      appearance={miniApp.isDark ? 'dark' : 'light'}
      platform={['macos', 'ios'].includes(lp.platform) ? 'ios' : 'base'}
      id='app-root'
    >
      <Router location={location} navigator={reactNavigator}>
        <Routes>
          {/* <Route path="/" element={<Login />} />  */}
          <Route path="/" element={<Claim />} />
          <Route path="/check" element={<Check />} />
          <Route path="/rising" element={<Rising />} />
          <Route path="/share" element={<Share />} />
          <Route path="/" element={<Layout />}>
            {routes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<route.element />}
              />
            ))}
          </Route>
        </Routes>
      </Router>
    </AppRoot>
  );
};
