import { useTonConnectModal, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Address } from '@ton/ton';
import { Link } from 'react-router-dom';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'

import { shortenAddress } from '@/lib';
import { AppContext } from '@/context';

export function WalletButton() {
  const { walletAddress, setWalletAddress } = useContext(AppContext);

  const modal = useTonConnectModal();
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();

  useEffect(() => {
    if (wallet) {
      setWalletAddress(Address.parse(wallet.account.address).toString())
    }
  }, [wallet])

  const handleConnect = () => {
    modal.open();
  }

  const handleDisconnect = () => {
    tonConnectUI.disconnect();
    setWalletAddress("");
  }

  if (!walletAddress) {
    return (
      <button type='button' className='btn-solid w-full h-8'
        onClick={handleConnect}
      >
        Connect Wallet
      </button>
    );
  }

  return (
    <Menu>
      <MenuButton className='btn-solid w-full h-8'>
        {shortenAddress(walletAddress)}
      </MenuButton>
      <MenuItems anchor="bottom" className="bg-dark-200 px-4 py-2 mt-4 flex flex-col gap-4">
        <MenuItem>
          <Link to={'/profile'}>
            <span className='text-sm hover:font-semibold'>
              My Profile
            </span>
          </Link>
        </MenuItem>
        <MenuItem>
          <button type='button' onClick={handleDisconnect}
            className='text-sm hover:font-semibold'>
            Disconnect
          </button>
        </MenuItem>
      </MenuItems>
    </Menu>
  )
}