export type JettonToken = {
    jetton_master_address: string;
    jetton_name: string;
    jetton_symbol: string;
    jetton_image: string;
    jetton_description: string;
    telegram_channel_link: string;
    telegram_chat_link: string;
    creator_address: string;
    creator_uid: number;
    creator_name: string;
    creator_image: string;
    create_time: number;
    total_supply: string;
    total_volume: string;
    complete_time: number;
    telegram: string;
    website: string;
    twitter: string;

    ton_amount: string;
    jetton_amount: string;
    order_side: string;

    reward:string;
    user_uid:number;
    user_name:string
};

export type UserJetton = {
    jetton_balance: string;
    last_ton_amount: string;
    last_jetton_amount: string;
} & JettonToken;

export type JettonHolder = {
    user_address: string;
    amount: string;
};

export type LeaderboardResponse = {
    my_rank: number;
    ranks: UserRank[];
};

export type UserRank = {
    uid: number;
    name: string;
    address: string;
    image: string;
    trading_point: string;
    farm_boost: number;
};

export type TokenInput = {
    avatar: string;
    name: string;
    ticker: string;
    description: string;
    telegram: string;
    twitter: string;
    website: string;
    amount: number;
}

export type UserInfo = {
    user_address: string;
    user_uid: number;
    user_username: string;
    user_name: string;
    user_image: string;
    jetton_buy_volume: string;
    jetton_sell_volume: string;
    jetton_created: number;
    jetton_completed: number;
    referrer_uid: number;
    referrals_amount: number;
    base_point: string,
    farm_boost: string
}

export type TransactionRecord = {
    user_address: string
    user_name: string
    user_image: string
    ton_amount: string
    jetton_amount: string
    timestamp: string,
    type: string
    hash: string
}

export type ExTransaction = {
    timestamp: number;
    tonAmount: number;
    jettonAmount: number;
};

export type URLArgs = {
    path: string;
    jetton?: string;
    referrer?: number; // telegram user id
}

export enum TokenFilter {
    ALL = "all",
    MY = "my_tokens"
}

export enum TokenSort {
    ONE_DAY = "24h",
    LASTEST = "latest",
    MKTCAP = "supply"
}

export enum TokenOrder {
    DESC = "desc",
    ASC = "asc"
}

export type FilterParams = {
    sortBy: TokenSort;
    filterBy: TokenFilter;
    orderBy: TokenOrder;
    search: string;
}
