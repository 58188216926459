import React, { PropsWithChildren, useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AppContext } from '@/context'
import { WalletButton } from '@/components/common/WalletButton'

export function Header() {
  const { latestToken } = useContext(AppContext);
  const navigate = useNavigate()
  const location = useLocation()

  return <header className="sticky top-0 z-10 px-4 py-2 bg-dark-100 leading-[48px] h-13">
    <div className="flex gap-4 items-center">
      <div className="flex-1">
        {
          latestToken ?
            <Link style={{ textDecoration: 'none' }} to={`/trading/${latestToken.jetton_master_address}`}>
              <TailwindButton className='animate-shake' icon={<img width={24} src={latestToken.jetton_image} />}>
                {"$" + latestToken.jetton_symbol}
              </TailwindButton>
            </Link>
            :
            <Link style={{ textDecoration: 'none' }} to={`/create`}>
              <TailwindButton>
                Create Now!
              </TailwindButton>
            </Link>
        }
      </div>
      <div className="flex-1">
        <WalletButton />
      </div>
    </div>
  </header>
}

const LuckyButton = ({ children }: PropsWithChildren) => {
  const buttonClasses = `
    uppercase
    bg-black hover:bg-gray-800
    border border-[#2b649f]
    text-[#2b649f] font-bold 
    gap-2 px-2 py-1 leading-[24px] 
    flex items-center justify-center w-full  
  `;
  return <button className={buttonClasses}>
    {children}
  </button>
}

const TailwindButton = (
  { icon, children, className = '' }:
    { icon?: React.ReactElement, children: React.ReactNode, className?: string }
) => {
  const buttonClasses = `
    uppercase
    bg-black hover:bg-gray-800
    border border-[#2b649f]
    text-[#2b649f] font-bold 
    gap-2 px-2 py-1 leading-[24px] 
    flex items-center justify-center w-full ${className}
  `;

  return <button className={buttonClasses}>
    {icon && React.cloneElement(icon as React.ReactElement)}
    {children}
  </button>;
};
