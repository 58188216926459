import { useContext, useEffect, useState } from 'react';

import Logo from '@/assets/ic_pumphub.svg'
import PunpIcon from '@/assets/punpIcon.svg'
import Congratulate from '@/assets/congratulate.svg'

import Plane from '@/assets/plane.svg'
import Plane2 from '@/assets/plane2.svg'

import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { createUser, isPremium } from '@/api/user';
import { useLaunchParams } from '@telegram-apps/sdk-react';
import toast from 'react-hot-toast';
import { AppContext } from '@/context';

import './index.less'
import { globalData } from '@/lib/constants';

import { generateLink } from "@/lib/generateShareLink";
import { APP_URL } from "../../App";

export function Share() {

  const lp = useLaunchParams();
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user_id } = useParams();
  console.log(user_id);

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const point = queryParams.get('point')

  const { userProfile, isLoading } = useContext(AppContext);

  const myLink = generateLink(APP_URL, {
    path: "",
    jetton: "",
    referrer: userProfile?.user_uid ?? 0,
  })
  // useEffect(() => {
  //   if (userProfile) {
  //     // navigate('/home',{ replace: true });
  //   }
  // }, [
  //   userProfile
  // ])


  const handleSignin = async () => {
    const user = lp.initData?.user;
    console.log(user, '$$$$$');

    if (user) {
      const user_uid = user.id;
      const user_image = user.photoUrl ?? "";
      const user_name = user.firstName + user.lastName;
      const user_username = user.username;
      const is_premium = user.isPremium ? 1 : 0
      setDisabled(true);

      try {

        let createUserResult = await createUser({
          user_address: "",
          user_uid,
          user_image,
          user_name,
          user_username,
          is_premium,
          referrer: globalData.referrer,
        });

        const { data } = createUserResult

        if (data.success) {
          toast.success("Login successful");
          navigate('/home', { replace: true });
          localStorage.setItem("basePoint", point ?? "0");
        }
        else {
          toast.error("Login failed");
        }
      }
      catch (ex) {
        console.log(ex);
        toast.error("Get error while sigin");
      }

      setDisabled(false);
    }
    else {
      toast.error("You don't have valid session");
    }
  }

  const shareEarnmore = async () => {
    const link = encodeURIComponent(myLink);
    const text = encodeURIComponent('Help me to become best of the best in @pumphubprobot and get some point');
    const telegramShareUrl = `https://t.me/share/url?url=${link}&text=${text}`;
    window.open(telegramShareUrl, '_blank');
  }

  if (isLoading) return <></>;

  return <div className='share-container'>
    <div className='h-1/4 flex flex-col items-center justify-center mt-5'>
      <img className='share-logo' src={PunpIcon} width={44} alt="PunpIcon" />
      <img className='share-title h-10' src={Logo} alt="logo" />
      <span className='share-tap'>Earn $PUMP With Social Engagements</span>
    </div>

    <div className='h-2/4 flex flex-col items-center justify-center mb-4'>
      <img className='share-con h-4' src={Congratulate} alt="logo" />
      <div className='share-point relative'>
        {/* <img className='share-con h-4' src={Congratulate} alt="logo" /> */}
        <div className='pl-6 pt-2 text-white'>
          <span className=' pointText'>POINTS</span>
        </div>
        <div className='text-center mt-5 text-white'>
          <span className='text-6xl pointNumber'>{point}</span>
        </div>

        <img src={Plane} alt="" className='absolute -top-8 right-2' />
        <img src={Plane2} alt="" className='absolute -left-8 bottom-[-80px]' />
      </div>
    </div>


    <div className='h-1/4  w-full flex flex-col items-center justify-center mb-5'>
      <button className='w-4/5 justify-center items-center flex bg-[#0A0A0D]' type='button' onClick={shareEarnmore}
        id='share-btn'>
        share to earn more
      </button>
      <button className='w-4/5 justify-center items-center flex mt-6 bg-[#0297E8]' type='button' onClick={handleSignin}
        id='share-btn'>
        START
      </button>
    </div>
  </div>
}
