import { useEffect, useMemo, useState } from 'react';
import { BONDING_CURVE, MAX_SUPPLY } from '@/lib/constants';
import { calculateReceiveToken, calculateReceiveTON, validNumber } from '@/lib';
import { BuyButton } from '@/components/Pages/Home/BuyButton';
import { SellButton } from '@/components/Pages/Home/SellButton';
import { ShareLink } from '@/components/Trading/ShareLink';

import { JettonToken } from '@/lib/types';
import { TonConnectUI } from '@tonconnect/ui-react';
import { Address } from '@ton/ton';
import { getBalance, getLaunched, getTonBalance } from '@/contract';
import { Box } from '@mui/material';

import './index.less';

export function TradingPanel({
  tonConnectUI,
  isBuy,
  isLaunched,
  tokenInfo,
  totalSupply,
  refresh,
}: {
  tonConnectUI: TonConnectUI,
  isBuy: boolean;
  isLaunched: boolean;
  tokenInfo: JettonToken;
  totalSupply: number;
  refresh: () => void;
}) {
  const [balance, setBalance] = useState<number>(0);
  const [tokenbalance, setTokenBalance] = useState<number>(0);
  const [amountInput, setAmountInput] = useState("");

  useEffect(() => {
    fetchBalance();

    const balanceTimer = setInterval(() => {
      fetchBalance();
    }, 1000 * 5);

    return () => clearInterval(balanceTimer);
  }, [
    tonConnectUI.account
  ])

  const amount = useMemo(() => {
    return validNumber(parseFloat(amountInput));
  }, [
    amountInput
  ]);

  const jettonAddress = useMemo(() => {
    return Address.parse(tokenInfo.jetton_master_address);
  }, [
    tokenInfo.jetton_master_address
  ])

  const receiveAmount = useMemo(() => {
    return isBuy ? calculateReceiveToken(amount, totalSupply) : calculateReceiveTON(amount, totalSupply);
  }, [
    amount,
    isBuy
  ])

  const percentSupply = useMemo(() => {
    return (isBuy ? receiveAmount / BONDING_CURVE : amount / BONDING_CURVE) * 100;
  }, [
    amount,
    receiveAmount,
    isBuy
  ])

  const tradingFee = useMemo(() => {
    return isBuy ? 0.03 : 0.1;
  }, [
    isBuy
  ])

  const fetchBalance = async () => {
    if (tonConnectUI.account) {
      const owner = Address.parse(tonConnectUI.account.address);
      const tonBalance = await getTonBalance(owner);
      setBalance(tonBalance);

      const tokenBalance = await getBalance(owner, jettonAddress);
      setTokenBalance(tokenBalance);
    }
  }

  const handleRefresh = () => {
    fetchBalance();
    refresh();
  }

  return <div className='trading-coin-detail-container'>
    <div className="trading-input-container">
      <input className='trading-input' type="text" placeholder="0" value={amountInput} onChange={e => setAmountInput(e.target.value)} />
      <div className="ton-tooltip">{isBuy ? 'TON' : tokenInfo.jetton_symbol}</div>
    </div>

    <div className="trading-detail">
      <div className="detail-item">
        <span className="label">Balance:</span>
        <span className="value">{(isBuy ? balance : tokenbalance).toFixed(2)} {isBuy ? 'TON' : tokenInfo.jetton_symbol}</span>
      </div>
      <div className="detail-item">
        <span className="label">You Receive:</span>
        <span className="value">{isLaunched ? '-' : `${receiveAmount.toFixed(2)} ${!isBuy ? 'TON' : tokenInfo.jetton_symbol}`}</span>
      </div>
      <div className="detail-item">
        <span className="label">Percentage of supply:</span>
        <span className="value">{isLaunched ? '-' : `${percentSupply.toFixed(2)}%`}</span>
      </div>
    </div>

    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0, marginTop: '8px' }}>
      <Box sx={{ width: '45px' }} />
      <Box sx={{ flex: 1 }}>
        {
          isBuy ?
            <BuyButton
              tonConnectUI={tonConnectUI}
              jettonAddress={jettonAddress}
              amount={amount}
              balance={balance}
              isDedust={isLaunched}
              setAmount={setAmountInput}
              refresh={handleRefresh}
            />
            : <SellButton
              tonConnectUI={tonConnectUI}
              jettonAddress={jettonAddress}
              amount={amount}
              balance={tokenbalance}
              isDedust={isLaunched}
              setAmount={setAmountInput}
              refresh={handleRefresh}
            />
        }
      </Box>
      <ShareLink address={jettonAddress.toString()} />
    </Box>

    <div className='text-center my-2'>
      Transaction Fee: ~{tradingFee} TON
    </div>
  </div>

}

