import { useContext, useEffect, useState } from 'react';

import CheckTitle from '@/assets/checkTitle.svg'

import { useNavigate, useParams } from 'react-router-dom';
import { registerTime } from '@/api/user';
import { useLaunchParams } from '@telegram-apps/sdk-react';
import toast from 'react-hot-toast';
import { AppContext } from '@/context';

import './index.less'
import { globalData } from '@/lib/constants';
import { CheckCircleIcon } from '@heroicons/react/24/outline'

type CheckItem = {
  title: string;
  check?: boolean;
  progress:number
}

export function Check() {

  const lp = useLaunchParams();
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user_id } = useParams();
  // console.log(user_id);

  const { userProfile, isLoading } = useContext(AppContext);

  const [point, setPoint] = useState<number>(0)

  const [checkList,setCheckList] = useState<CheckItem[]>([
    {
      title:'Account Age Verified',
      // check:false,
      progress:0
    },
    {
      title:'Activity Level Analyzed',
      // check:false,
      progress:0
    },
    {
      title:'Telegram Premium Checked',
      // check:false,
      progress:0
    },
    {
      title:'OG Status Confirmed',
      // check:false,
      progress:0
    }
  ])

  useEffect(()=>{
    const user = lp.initData?.user
    if (!user) return
    const fetchData = async () => {
      try {
        let results = await registerTime(user.id);
        console.log(results);
        
        const startDate = new Date(results);
        const currentDate = new Date() 
        const yearDifference = currentDate.getFullYear() - startDate.getFullYear();
        let monthDifference = currentDate.getMonth() - startDate.getMonth()
        let dayDifference = currentDate.getDay() - startDate.getDay()
        if(dayDifference) {
          monthDifference+=1
        }
        const decimalPoint = yearDifference + (monthDifference / 12)
        const totalMonthDifference = Math.ceil(decimalPoint)
        
        let is_premium = user.isPremium ?? false
     
        let point = decimalPoint * 12 * 150 + (is_premium ? 600 : 0) + ((totalMonthDifference>=5 && is_premium) ?10000:0)
        
        checkList.forEach((_, index) => {
          setTimeout(() => {
            setCheckList(prevList =>
              prevList.map((item, i) => 
                i === index ? { ...item, progress: 100 } : item
              )
            );
          }, index * 1000); // 每个进度条延迟 1000ms
        })
        setPoint(point)
        setTimeout(() => {
          navigate(`/rising?point=${point}&year=${totalMonthDifference}`,{ replace: true })
        }, 5 * 1000);
        // 处理结果
      } catch (error) {
        // 处理错误
        console.error(error);
      }
    }
    fetchData()
  },[])

  return <div className='check-container'>
    <img className='check-title' src={CheckTitle} height={36} alt="logo" />
    <div className='mt-16 w-full flex flex-col items-center'>
      {
        checkList.map((v,index)=>{
          return <>
            <Process title={v.title} check={v.check} progress={v.progress} key={index}/>
          </>
        })
      }
    </div>
  </div>
}

type processProps = {
  title:string;
  check?:boolean;
  progress:number
}

function Process ( { title ,progress}: processProps) {
  return (
    <div className='w-4/5 mb-7'>
      <div className='w-full flex justify-between items-center'>
        <span className='text-white text-sm'> {title}</span>
        {/* <CheckCircleIcon color="#00c1ff" className="w-5 h-5" /> */}
        <CheckCircleIcon color={progress==100?"#00c1ff":"#fff"} className="w-5 h-5" />
      </div>
      <div className='progress-bar w-full process h-2.5 border border-solid rounded-full flex items-center pl-0.5 pr-0.5 mt-1'>
        <div className='h-1.5 bg-[#0297E8] border border-solid rounded-full' style={{ width: `${progress}%`, transition: 'width 1s ease-in-out' }}></div>
      </div>
    </div>
  )
}