import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Address } from '@ton/ton';
import { TonConnectUI } from '@tonconnect/ui-react';
import styled from '@emotion/styled';
import { claimToken } from '@/contract';

import './index.less';

const ClaimButtonInner = styled(Button)({
  color: 'white',
  textAlign: 'center',
  fontSize: '18px',
  width: '100%',
  backgroundColor: '#50a275',
  '&:hover': {
    backgroundColor: '#50a275'
  }
});

export function ClaimButton({
  tonConnectUI,
  jettonAddress,
}: {
  tonConnectUI: TonConnectUI | null,
  jettonAddress: Address,
}) {
  const [isDisabled, setDisabled] = useState(false);

  const handleClaim = async () => {

    if (!tonConnectUI || !tonConnectUI.account) {
      toast.error("You need to connect wallet");
      return;
    }

    toast.loading("Please wait while claim tokens...", {
      id: "pumphub"
    });
    setDisabled(true);

    try {
      await claimToken(tonConnectUI, jettonAddress);

      toast.success("Claim tokens successfully", {
        id: "pumphub"
      });
    }
    catch (ex) {
      console.log(ex);
      toast.error("Claim tokens failed", {
        id: "pumphub"
      });
    }

    setDisabled(false);
  }


  return <div className='claim-container'>
    <ClaimButtonInner onClick={handleClaim} disabled={isDisabled}>
      Claim Token
    </ClaimButtonInner>
    <p className='claim-note'>
      Claim your jettons to trade on DeDust
    </p>
  </div >
}
