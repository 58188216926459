import { HomeLinks } from '@/components/Pages/Home/HomeLinks';
import { TradingSummary } from '@/components/Pages/Home/TradingSummary';
import { HomeUserCard } from '@/components/Pages/Home/HomeUserCard';
import { StartNewCoin } from '@/components/Pages/Home/StartNewCoin';
import { HomeTokenList } from '@/components/Pages/Home/HomeTokenList';
import { Header } from '@/components/Header';
import { LuckyButton } from '@/components/Pages/Home/LuckButton';
import { LuckyModal } from "@/components/common/LuckyModal"
import React,{useState,useEffect} from 'react';

export const HomePage = () => {
  const [open,setOpen] = useState<boolean>(false)
  useEffect(() => {
    const modalHasOpened = sessionStorage.getItem('modalHasOpened');
    if (modalHasOpened!=='true') {
      setOpen(true);
      sessionStorage.setItem('modalHasOpened', 'true');
    }
  }, []);
  return (
    <div className='bg-home w-full'>
      <Header />

      <div className='px-3 py-2'>
        <HomeLinks />
        <TradingSummary />
        <HomeUserCard />
        <LuckyButton />
        <StartNewCoin />
        <HomeTokenList />
        <LuckyModal open={open} onClose={(value)=>{setOpen(value)}} />
      </div>
    </div>
  )
};
