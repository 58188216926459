import { useContext, useEffect, useMemo, useState } from 'react';

import RisingTap from '@/assets/risingtap.svg'
import RisingTitle from '@/assets/risingtitle.svg'
import Badge from '@/assets/badge.svg'
import Badge2 from '@/assets/badge-2.svg'
import Badge3 from '@/assets/badge-3.svg'

import Badge4 from '@/assets/badge-4.svg'
import Badge5 from '@/assets/badge-5.svg'
import Badge5Up from '@/assets/badge-5+.svg'

import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useLaunchParams } from '@telegram-apps/sdk-react';
import toast from 'react-hot-toast';
import { AppContext } from '@/context';

import './index.less'
import { globalData } from '@/lib/constants';


export function Rising() {

  const lp = useLaunchParams();
  const navigate = useNavigate();
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);
  const point = queryParams.get('point');
  const year = queryParams.get('year')
  
  // const { user_id } = useParams();
  // console.log(point,'$$$$$');

const yearBg = useMemo(() => {
  switch (year) {
    case "1":
    return Badge
    case "2":
    return Badge2
    case "3":
    return Badge3
    case "4":
    return Badge4
    case "5":
    return Badge5
    default: return Badge5Up
  }
}, [year])

  const { userProfile, isLoading } = useContext(AppContext);

  useEffect(() => {

  }, [])

  return <div className='rising-container'>
    <img className='rising-title mt-14' src={RisingTitle} height={36} alt="RisingTitle" />
    <div className='flex items-center flex-col'>
      <img className='rising-tap' src={RisingTap} height={36} alt="RisingTap" />
      <img src={yearBg} alt="Badge" className=' ' />
    </div>
    <button className='justify-center items-center flex w-4/5 mb-14' type='button' onClick={() => { navigate(`/share?point=${point}`, { replace: true }) }}
      id='login-btn'>
      continue
    </button>
  </div>
}
