import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Address } from '@ton/ton';

import { fetchTokens } from '@/api/jettons';
import { JettonToken } from '@/lib/types';
import { getLaunched, getTotalSupply, getTransferable } from '@/contract';

import { TradingPanel } from '@/components/common/TradingPanel';
import { Loading } from '@/components/common/Loading';
import { SwitchTab, TradeType } from '@/components/common/SwitchTabButton';

import { TradingHistory } from '@/components/Trading/TradingHistory';
import { TransactionHistory } from '@/components/Trading/TransactionHistory';
import { MarketcapProgress } from '@/components/Trading/MarketcapProgress';

import { Box } from '@mui/material';
import { ClaimButton } from '@/components/Pages/Home/ClaimButton';
import { useTonConnectUI } from '@tonconnect/ui-react';
import { FactoryJettonWallet } from '@/contract/FactoryJetton_FactoryJettonWallet';
import { Header } from '@/components/Header';
import { TokenInfo } from '@/components/Trading/TokenInfo';
import { formatBN } from '@/lib/format';

const SUPPLY_UPDATE_INTERVAL = 5;

export function TradingPage() {
  const { address } = useParams();
  const [tonConnectUI] = useTonConnectUI();

  const [tokenInfo, setTokenInfo] = useState<JettonToken>();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(TradeType.Buy);

  const [totalSupply, setTotalSupply] = useState<number>(0);
  const [isClaimable, setClaimable] = useState<boolean>(false);
  const [isLaunched, setLaunched] = useState<boolean>(false);

  useEffect(() => {
    fetchTotalSupply();

    const supplyTimer = setInterval(() => {
      fetchTotalSupply();
    }, 1000 * SUPPLY_UPDATE_INTERVAL);

    return () => clearInterval(supplyTimer);
  }, [
    address
  ])

  useEffect(() => {
    if (!isClaimable) {
      fetchClaimable();

      const claimTimer = setInterval(() => {
        fetchClaimable();
      }, 1000 * 10);

      return () => clearInterval(claimTimer);
    }
  }, [
    address,
    tonConnectUI.account,
    isClaimable,
  ])

  useEffect(() => {
    if (address) {
      loadToken();
    }
  }, [address]);

  const fetchTotalSupply = async () => {
    if (address) {
      const jettonAddress = Address.parse(address);
      const totalSupply = await getTotalSupply(jettonAddress);
      setTotalSupply(totalSupply);
    }
  }

  const loadToken = async () => {
    try {
      setLoading(true);
      const res = await fetchTokens({ address });
      const tokenInfo = res?.['data']?.[0] as JettonToken;
      setTokenInfo(tokenInfo);
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
    }
  }

  const fetchClaimable = async () => {
    try {
      if (address) {
        const master = Address.parse(address);
        const isLaunched = await getLaunched(master);
        setLaunched(isLaunched);

        if (isLaunched && tonConnectUI.account) {
          const owner = Address.parse(tonConnectUI.account.address);
          const wallet = await FactoryJettonWallet.fromInit(owner, master);
          const isTransferable = await getTransferable(wallet.address);
          setClaimable(!isTransferable);
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const refresh = () => {
    fetchTotalSupply();
    fetchClaimable();
  }

  if (!tokenInfo || loading) {
    return <Loading />
  }

  return <div className='w-full'>
    <Header />

    <div className='px-3 py-2 flex flex-col gap-4'>

      <TokenInfo token={tokenInfo} />

      <MarketcapProgress
        totalSupply={totalSupply}
        lastTon={formatBN(tokenInfo.ton_amount)}
        lastJetton={formatBN(tokenInfo.jetton_amount)}
      />

      {
        isClaimable ?
          <Box sx={{ flex: 1 }}>
            <ClaimButton
              tonConnectUI={tonConnectUI}
              jettonAddress={Address.parse(tokenInfo.jetton_master_address)}
            />
          </Box>
          : <div className='trading-panel'>
            <SwitchTab
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
            <TradingPanel
              tonConnectUI={tonConnectUI}
              isBuy={activeTab == TradeType.Buy}
              isLaunched={isLaunched}
              tokenInfo={tokenInfo}
              totalSupply={totalSupply}
              refresh={refresh}
            />
          </div>
      }
      <TradingHistory tokenInfo={tokenInfo} address={tokenInfo?.jetton_master_address} />
      <TransactionHistory tokenInfo={tokenInfo} address={address} />
    </div>
  </div>
}
