import { useEffect, useState } from 'react';
import { fetchHolders } from '@/api/jettons';
import { JettonHolder, JettonToken } from '@/lib/types';
import { shortenAddress } from '@/lib';
import { fromNano } from '@ton/ton';
import { Loading } from '@/components/common/Loading';
import { MAX_SUPPLY } from '@/lib/constants';
import { formatBN } from '@/lib/format';

import './index.less';

export const HoldersPanel = ({
  address,
  tokenInfo
}: {
  address: string
  tokenInfo: JettonToken
}) => {
  const [holders, setHolders] = useState<JettonHolder[]>([]);
  const [loading, setLoading] = useState(false);
  const { total_supply } = tokenInfo

  useEffect(() => {
    const fetchTokenHolders = async () => {
      setLoading(true);
      if (address) {
        try {
          const resp = await fetchHolders({
            jetton_master_address: address,
            user_address: "",
            page: 0,
            page_size: 10,
          });
          setHolders(resp['data']);
        } catch (e) {
          console.error(e);
          setHolders([]);
        } finally {
          setLoading(false);
        }
      }
    }
    fetchTokenHolders();

    const holdersTimer = setInterval(() => {
      fetchTokenHolders();
    }, 1000 * 10);

    return () => clearInterval(holdersTimer);
  }, [
    address
  ])

  if (loading) {
    return <Loading />
  }

  if (holders.length == 0) {
    return <div className='holder-empty'>
      --- No holders ---
    </div>
  }

  return (
    <div className='holders-list'>
      {
        holders.map((holder, idx) => <div className='holder-item' key={`holder-${holder.user_address}-${idx}`}>
          <div className='holder-address'>
            {shortenAddress(holder.user_address)}
          </div>
          <div className='holder-amount'>
            {parseFloat(fromNano(holder.amount)).toFixed(2)}
            {
              holder ?
                `(${(formatBN(holder.amount) / Number(MAX_SUPPLY) * 100).toFixed(2)}%)` :
                `(No holder now)`
            }
            {/* {parseFloat(fromNano(holder.amount)).toFixed(2)} */}
          </div>
        </div>
        )
      }
    </div>
  )
}
