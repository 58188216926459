import { useContext, useEffect, useRef, useState } from 'react';
import { ColorType, createChart, IChartApi, ISeriesApi, SeriesType, UTCTimestamp } from 'lightweight-charts';

import './index.less';
import { fetchTransactions } from '@/api/jettons';
import { ExTransaction, TransactionRecord } from '@/lib/types';
import { AppContext } from '@/context';
import { formatBN } from '@/lib/format';
import { generateCandles } from '@/lib';

const CHART_OPTIONS = {
  HEIGHT: 350,
}

type CandleStick = {
  time: UTCTimestamp;
  open: number;
  close: number;
  high: number;
  low: number;
};

const KLineChart = ({
  jettonAddress
}: {
  jettonAddress: string
}) => {
  const { tonPrice } = useContext(AppContext);
  const chartContainerRef = useRef(null);
  const chartRef = useRef<IChartApi | null>(null);
  const [series, setSeries] = useState<ISeriesApi<"Candlestick"> | null>(null);
  const [transactions, setTransactions] = useState<ExTransaction[]>([]);

  useEffect(() => {
    fetchHistory();

    const historyTimer = setInterval(() => {
      fetchHistory();
    }, 1000 * 10);

    return () => clearInterval(historyTimer);
  }, [jettonAddress])

  const fetchHistory = async () => {
    const TimeNow = new Date().getTime();
    // const TimeNowFiveMinutesAgo = TimeNow - 5 * 60 * 1000;
    // fetch data
    try {
      const res = await fetchTransactions({
        jetton_master_address: jettonAddress,
        user_address: "",
        start: 0,
        end: TimeNow,
      });
      if (res.data) {
        const transactions = res.data as TransactionRecord[];
        setTransactions(transactions.map(t => ({
          timestamp: parseInt(t.timestamp),
          tonAmount: formatBN(t.ton_amount),
          jettonAmount: formatBN(t.jetton_amount),
        })));
      }
    } catch (e) {
      console.error(e)
    }
    finally {
    }
  }

  useEffect(() => {
    if (chartContainerRef.current) {
      chartRef.current = createChart(chartContainerRef.current, {
        // @ts-expect-error fix me
        width: chartContainerRef.current.clientWidth,
        height: CHART_OPTIONS.HEIGHT,
        layout: {
          background: {
            color: '#000000',
            type: ColorType.Solid,
          }, // 背景颜色黑色
          textColor: '#ffffff', // 文字颜色白色
        },
        grid: {
          vertLines: {
            color: '#404040', // 垂直网格线颜色
          },
          horzLines: {
            color: '#404040', // 水平网格线颜色
          },
        },
        timeScale: {
          timeVisible: true,
          fixLeftEdge: true,
          shiftVisibleRangeOnNewBar: true,
          borderColor: '#485c7b', // 时间刻度边框颜色
        },
        rightPriceScale: {
          borderColor: '#485c7b', // 价格刻度边框颜色
        },
      });

      const candlestickSeries = chartRef.current.addCandlestickSeries({
        priceFormat: {
          minMove: 0.000000001,
          precision: 9,
        },
      });
      setSeries(candlestickSeries);
    }

    const handleResize = () => {
      // @ts-expect-error fix me
      chartRef.current.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      if (chartRef.current) {
        chartRef.current.remove();
      }
    };
  }, [
  ]);

  useEffect(() => {
    if (series) {
      const candles: CandleStick[] = generateCandles(transactions, tonPrice ?? 0);
      series.setData(candles);
    }
  }, [
    transactions,
    series
  ])

  return <div ref={chartContainerRef} className='k-line-chart' />;
};

export default KLineChart;
