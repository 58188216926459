import React, { type FC, useState } from "react";
import Dialog from '@mui/material/Dialog';
import slotBg from "@/assets/luckyTitle.png"
import LuckyModalBg from '@/assets/LuckyModalbg.svg'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { LinkButton } from "@/components/common/LinkButton"
export interface SimpleDialogProps {
   open: boolean;
   onClose: (value: boolean) => void;
}

const buttonStyle: React.CSSProperties = {
   backgroundColor: '#FFA500', // 橙色
   color: 'white',
   border: 'none',
   borderRadius: '20px',
   // fontSize: '16px',
   cursor: 'pointer',
   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
}

// 按下时的样式
const clickedButtonStyle: React.CSSProperties = {
   ...buttonStyle,
   backgroundColor: '#FF8C00', // 深橙色
   transform: 'scale(0.95)',   // 缩小效果
}

export const LuckyModal = (props: SimpleDialogProps) => {

   const { onClose, open } = props

   const handleClose = (value: boolean) => {
      onClose(value);
   };

   return (
      <Dialog onClose={handleClose} open={open} className="flex flex-col" sx={{
         backgroundColor: 'transparent', // 设置背景透明
         backgroundImage: 'none', // 去掉背景图像
         boxShadow: 'none', // 去除阴影
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center'
      }}
         PaperProps={{
            sx: {
               backgroundColor: 'transparent', // 设置背景透明
               backgroundImage: 'none', // 去掉背景图像
               boxShadow: 'none', // 去除阴影
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center'
            },
         }}>
         <img src={slotBg} className='w-3/4 pb-2' />
         <div className="flex flex-col justify-center items-center relative">
            <img src={LuckyModalBg} alt="" />
            <Button btn={(value) => handleClose(value)}></Button>
         </div>
         <div className="pt-4">
            <button onClick={() => handleClose(false)}>
               <XCircleIcon className="size-8 text-white opacity-[.2]" />
            </button>
         </div>
      </Dialog >
   )
}

export interface ButtonProps {
   btn: (value: boolean) => void;
}

function Button(props: ButtonProps) {
   const { btn } = props
   // 定义按钮按下状态
   const [isClicked, setIsClicked] = useState(false);

   // 处理点击事件
   const handleMouseDown = () => {
      setIsClicked(true);
   };

   const handleMouseUp = () => {
      setIsClicked(false);
   }

   return (
      <button
         onMouseDown={handleMouseDown}  // 按下鼠标
         onMouseUp={handleMouseUp}      // 松开鼠标
         onMouseLeave={handleMouseUp}  // 如果鼠标离开按钮，状态复原
         style={isClicked ? clickedButtonStyle : buttonStyle} className="absolute bottom-5 text-xs font-bold px-12 py-2">

         <div onClick={() => btn(false)} className="">
            <LinkButton to='/lucky' className='w-full h-full' >
               PLAY FOR PRIZES
            </LinkButton>
         </div>
      </button>
   )
}

