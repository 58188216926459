import { Dispatch, SetStateAction } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/system';

export enum TradeType {
  Sell = 'Sell',
  Buy = 'Buy',
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: '#252525',

  padding: 4,
  display: 'flex',
}));

const StyledToggleButton = styled(ToggleButton)(() => ({
  flex: 1,
  padding: '6px 0',
  textAlign: 'center',
  color: 'white',
  transition: 'background-color 0.3s',
  '&.Mui-selected': {
    backgroundColor: '#50a275',
    '&.switch-button-sell': {
      backgroundColor: '#E25463',
      '&:hover': {
        backgroundColor: '#E25463',
      }
    },
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: '#50a275',
    }
  },
  '&:not(.Mui-selected)': {
    border: '1px transparent solid',
  },
  '&.MuiToggleButton-root': {
    borderRadius: '8px',
  },
}));

export const SwitchTab = ({
  activeTab,
  setActiveTab,
}: {
  activeTab: string,
  setActiveTab: Dispatch<SetStateAction<TradeType>>,
}) => {

  // @ts-expect-error fix this
  const handleChange = (event, newTab) => {
    if (newTab !== null) {
      setActiveTab(newTab);
    }
  };

  return (
    <StyledToggleButtonGroup
      value={activeTab}
      exclusive
      onChange={handleChange}
    >
      <StyledToggleButton className='switch-button-buy' value={TradeType.Buy}>{TradeType.Buy}</StyledToggleButton>
      <StyledToggleButton className='switch-button-sell' value={TradeType.Sell}>{TradeType.Sell}</StyledToggleButton>
    </StyledToggleButtonGroup>
  );
};
