import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react"
import { UserTokenTab } from "./UserTokenTab"
import { LeaderboardTab } from "./LeaderboardTab"

export const UserTabs = () => {
  return (
    <div className='w-full my-8'>

      <TabGroup>
        <TabList className="flex gap-4 px-3 py-2">
          <Tab
            key={`user-tokens`}
            className="w-full rounded-md py-1 px-3 text-sm/6 font-semibold bg-dark-50 text-dark-700 focus:outline-none data-[selected]:bg-primary-100 data-[hover]:bg-primary-100/80 data-[selected]:text-white data-[hover]:text-white"
          >
            Token Space
          </Tab>
          <Tab
            key={`user-leaderboard`}
            className="w-full  rounded-md py-1 px-3 text-sm/6 font-semibold bg-dark-50 text-dark-700 focus:outline-none data-[selected]:bg-primary-100 data-[hover]:bg-primary-100/80 data-[selected]:text-white data-[hover]:text-white"
          >
            Leaderboard
          </Tab>
        </TabList>
        <TabPanels className="mt-3 border border-primary-100 rounded-xl p-2">
          <TabPanel className="">
            <UserTokenTab />
          </TabPanel>
          <TabPanel className="">
            <LeaderboardTab />
          </TabPanel>
        </TabPanels>
      </TabGroup>

    </div>
  )
}