import React, { useContext, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useTonWallet } from '@tonconnect/ui-react';
import { Address } from '@ton/ton';
import { Link } from 'react-router-dom';
import { AppContext } from '@/context';
import { ImageUploader } from '@/components/common/ImageUploader';
import { createUser } from '@/api/user';
import { AppLinks } from '@/components/Profile/AppLinks';
import { UserInfo } from '@/components/Profile/UserInfo';
import { UserTabs } from '@/components/Profile/UserTabs';

export const ProfilePage = () => {
  const { userProfile, fetchUserProfile } = useContext(AppContext);
  const wallet = useTonWallet();

  const address = useMemo(() => {
    if (wallet) {
      return Address.parse(wallet.account.address).toString();
    }
  }, [
    wallet
  ])

  const handleUploadAvatar = async (uri: string) => {

    toast.loading("Uploading avatar...", {
      id: "pumphub"
    });

    const { data } = await createUser({
      ...userProfile,
      user_image: uri
    });

    if (data.success) {
      fetchUserProfile();
      toast.success("Upload avatar successful", {
        id: "pumphub"
      });
    }
    else {
      toast.error("Upload avatar failed", {
        id: "pumphub"
      });
    }
  }

  return (

    <div className='w-full min-h-screen relative'>

      <div className='absolute top-0 left-0 right-0'>
        <img src='/images/bg_world.png' alt='World' className='w-full' />
      </div>

      <div className='w-full'>

        <div className='sticky top-0 z-[100]'>
          <div className={`w-full flex gap-2 justify-center items-center px-4 py-6`}>
            <Link to={'/'}>
              <img src='/images/ic_logo.svg' alt='Logo' className='h-7' />
            </Link>
          </div>
        </div>

        <div className='p-4'>

          <div className='w-full my-8'>
            <div className='relative w-full flex items-center justify-center pb-8'>
              <ImageUploader
                avatar={userProfile?.user_image}
                setAvatar={handleUploadAvatar} />
            </div>
          </div>

          <UserInfo
            userid={userProfile?.user_uid}
            username={userProfile?.user_name}
            address={address} />

          <UserTabs />

          <div className='w-full h-0.5 border border-dark-400 border-dashed my-6' />

          <AppLinks />

        </div>
      </div>

    </div>

  );
};