import { fetchUserJettons } from "@/api/user";
import { AppContext } from "@/context";
import { calculatePrice, calculateUserPrice, shortenAddress } from "@/lib"
import { formatBN, prettyNumber } from "@/lib/format";
import { UserJetton } from "@/lib/types";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loading } from "../common/Loading";

export const UserTokenTab = () => {
  const { userProfile, tonPrice } = useContext(AppContext);

  const [isLoading, setLoading] = useState<boolean>(false);
  const [tokens, setTokens] = useState<UserJetton[]>([]);

  useEffect(() => {
    loadUserJettons();
  }, []);

  const loadUserJettons = async () => {
    setLoading(true);

    try {
      const { data } = await fetchUserJettons(userProfile?.user_uid ?? 0);
      if (data) {
        setTokens(data as UserJetton[]);
      }
    }
    catch (ex) {
      console.log(ex)
      // setTonPrice(undefined);
    }

    setLoading(false);
  }

  return (
    <div className='w-full p-3'>

      {
        isLoading ? <Loading />
          : <div className="flex flex-col w-full gap-3">
            {
              tokens.length > 0 ?
                <>
                  {
                    tokens.map((jetton) =>
                      <Link to={`/trading/${jetton.jetton_master_address}`} key={`user-jetton-${jetton.jetton_master_address}`}>
                        <div className="w-full flex items-center gap-3">
                          <img src={jetton.jetton_image} alt={jetton.jetton_name} className="w-10 h-10 rounded-full" />

                          <div className="w-full flex flex-col">
                            <label className="text-md text-white">
                              {jetton.jetton_name}
                            </label>
                            <span className="text-xs text-dark-700">
                              Balance: {prettyNumber(jetton.jetton_balance)}
                            </span>
                          </div>

                          <span className="text-md text-success-200">
                            ${(calculateUserPrice(
                              formatBN(jetton.last_ton_amount),
                              formatBN(jetton.last_jetton_amount),
                              formatBN(jetton.jetton_balance),
                              tonPrice ?? 0)).toFixed(2)}
                          </span>

                        </div>
                      </Link>)
                  }
                </>
                : <div className="text-center text-sm text-dark-700 py-1">
                  No tokens you owned
                </div>
            }
          </div>
      }


    </div>
  )
}