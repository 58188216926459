import { BONDING_CURVE, INIT_TON, K, MAX_SUPPLY } from "./constants";
import { formatNumber } from "./format";
import { ExTransaction } from "./types"; 
import crypto from "crypto"

export async function sleep(time: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

export function shortenAddress(address: string, len: number = 3): string {
  const startChars = address.substring(0, len);
  const endChars = address.substring(address.length - len);
  const shortenedAddress = `${startChars}...${endChars}`;
  return shortenedAddress;
}

export const isEmpty = (val: string | undefined | null): boolean => {
  if (!val || val.trim().length == 0) {
    return true;
  }

  return false;
}

export const validNumber = (amount: number): number => {
  let val = amount;
  if (isNaN(val) || !val) {
    val = 0;
  }

  return val;
}

export const calculateSupply = (amount: number) => {
  const val = validNumber(amount);
  return MAX_SUPPLY - (K / (INIT_TON + val));
}

export const calculateTonBalance = (totalSupply: number) => {
  return (K / (MAX_SUPPLY - totalSupply)) - INIT_TON;
}

export const calculateReceiveToken = (tonAmount: number, totalSupply: number) => {
  const amount = validNumber(tonAmount);
  return MAX_SUPPLY - totalSupply - (K / ((K / (MAX_SUPPLY - totalSupply)) + amount));
}

export const calculateReceiveTON = (tokenAmount: number, totalSupply: number) => {
  const amount = validNumber(tokenAmount);
  return (K / (MAX_SUPPLY - totalSupply)) - (K / (MAX_SUPPLY - (totalSupply - amount)));
}

export const calculatePrice = (totalSupply: number, tonPrice: number) => {
  const jettonPrice = ((K / (MAX_SUPPLY - totalSupply)) ** 2 - INIT_TON ** 2) / K;
  return jettonPrice * tonPrice;
}

export const calculateUserPrice = (tonAmount: number, jettonAmount: number, jettonBalance: number, tonPrice: number) => {

  if (jettonAmount == 0) {
    return 0;
  }

  const price = tonAmount / jettonAmount;
  return price * tonPrice * jettonBalance;
}

// export const calculateMarketCap = (totalSupply: number, tonPrice: number) => {
//     const price = calculatePrice(totalSupply, tonPrice);
//     return price * MAX_SUPPLY;
// }

export const calculateMarketCap = (tonAmount: number, jettonAmount: number, tonPrice: number) => {

  if (jettonAmount == 0) {
    return 0;
  }

  const price = tonAmount / jettonAmount;
  return price * tonPrice * BONDING_CURVE;
}


export const calculateBoostBase = (jettonCreated: number, jettonBuyVolume: number) => {
  return 1 + Math.round(jettonCreated / 4) / 10 + Math.round(jettonBuyVolume / 100) / 10

}

export const calculateBasePoint = (jettonBuyVolume: number, jettonSellVolume: number) => {
  return Math.round(10 * (jettonBuyVolume + jettonSellVolume) / 10)

}

export const calculateFarmBoost = (boostBase: number, jettonComplete: number) => {
  const multiplier = Math.round(10 * 1.5 ** (Math.min(10, jettonComplete))) / 10
  return Math.round(10 * boostBase * multiplier) / 10
}

export const calculateShareBoost = (basePoint: string, farmBoost: string) => {
  return Number(basePoint) * Number(farmBoost)
}

export const calculateTradingPoint = (basePoint: number, farmBoost: number, referralsAmount: number, sharePoint: number) => {
  return basePoint * farmBoost * 0 + 0 * referralsAmount + sharePoint
  // return basePoint * farmBoost +  100 * referralsAmount +  sharePoint
}

export const calculateCurrentGrowth = (totalSupply: number, tonPrice: number) => {
  return calculatePrice(totalSupply, tonPrice) / calculatePrice(966394.187, tonPrice);
}

function timeToTz(originalTime: number) {
  return originalTime - (new Date().getTimezoneOffset() * 60);
}

// Function to generate candlestick data from transactions
export function generateCandles(transactions: ExTransaction[], tonPrice: number, interval: number = 60): any[] {

  if (transactions.length == 0) {
    return [];
  }

  transactions.sort((a, b) => a.timestamp - b.timestamp);

  // Step 2: Define variables to store candlestick data
  const candlestickData: any[] = [];
  let currentTime = Math.floor(transactions[0].timestamp / interval) * interval;
  const endTime = Math.ceil(transactions[transactions.length - 1].timestamp / interval) * interval;

  const firstPrice = transactions[0].tonAmount / transactions[0].jettonAmount * (tonPrice ?? 0);
  let open = firstPrice;
  let high = firstPrice;
  let low = firstPrice;
  let close = firstPrice;

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let idx = 0;
  while (currentTime < endTime) {

    open = close;
    high = open;
    low = open;
    close = open;

    const startIdx = idx;
    for (let i = startIdx; i < transactions.length; i++) {
      const { timestamp, tonAmount, jettonAmount } = transactions[i];
      if (timestamp >= (currentTime + interval)) {
        break;
      }

      const price = tonAmount / jettonAmount * (tonPrice ?? 0);

      if (high < price) {
        high = price;
      }
      if (low > price) {
        low = price;
      }
      close = price;

      idx = i;
    }

    if (idx > startIdx) {
      candlestickData.push({
        time: timeToTz(currentTime),
        open,
        high,
        low,
        close,
      });
    }

    currentTime += interval;
  }

  if (candlestickData.length == 0) {
    candlestickData.push({
      time: timeToTz(currentTime),
      open: close,
      high: close,
      low: close,
      close,
    });
  }

  return candlestickData;
}

export function getRandomInt(min: number, max: number) {
  const range = max - min + 1
  const bytesNeeded = Math.ceil(Math.log2(range) / 8)
  const randomBytes = crypto.randomBytes(bytesNeeded)

  let randomValue = 0
  for (let i = 0; i < bytesNeeded; i++) {
    randomValue = (randomValue << 8) | randomBytes[i]
  }

  return min + Math.floor(randomValue % range)
}
