
export const timeAgo = (unixTime: number) => {
  const now = new Date().getTime();
  const past = new Date(unixTime * 1000).getTime(); // 将 Unix 时间戳转换为毫秒
  const diffInSeconds = Math.floor((now - past) / 1000);

  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (const [unit, secondsInUnit] of Object.entries(intervals)) {
    const interval = Math.floor(diffInSeconds / secondsInUnit);
    if (interval > 1) {
      return `${interval} ${unit}s ago`;
    } else if (interval === 1) {
      return `${interval} ${unit} ago`;
    }
  }

  return 'just now';
};
