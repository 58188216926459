import { LinkButton } from "@/components/common/LinkButton"
import luckyButtonBg from "@/assets/luckyButton.png"
import sidedTon from "@/assets/sidedTon.png"
import ton1 from "@/assets/1ton.png"
import { HTMLAttributes } from "react"
export const LuckyButton = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={`w-full pt-7   ${className}`}{...props}>
      <div style={{
        boxShadow: "-4px -4px 0px 0px rgba(191, 80, 0, 0.60) inset, 4px 4px 0px 0px rgba(255, 234, 221, 0.70) inset"
      }} className={`w-full bg-yellow-500 relative   `} >
        <img src={sidedTon} style={{
          top: "-50%"
        }} className="absolute w-15 right-0" />
        <img src={ton1} style={{
          top: "-10%",
          transform: "rotate(15deg)"
        }} className="absolute w-10 left-5" />
        <button className="w-full   bottom-1 py-2">
          <LinkButton to='/lucky' className='text-black w-full h-full'>
            LUCKY PACKAGE
          </LinkButton>
        </button>
      </div>    </div>
  )
}
