import { useContext, useMemo } from 'react';

import { AppContext } from '@/context';
import { BONDING_CURVE } from '@/lib/constants';
import { calculateMarketCap } from '@/lib';


export const MarketcapProgress = ({
  totalSupply,
  lastJetton,
  lastTon,
}: {
  totalSupply: number,
  lastJetton: number,
  lastTon: number,
}) => {
  const { tonPrice } = useContext(AppContext);

  console.log(totalSupply)
  const percentSupply = useMemo(() => {
    return totalSupply / BONDING_CURVE * 100;
  }, [
    totalSupply
  ])

  const marketCap = useMemo(() => {
    if (tonPrice) {
      return calculateMarketCap(lastTon, lastJetton, tonPrice);
    }

    return 0;
  }, [
    tonPrice,
    totalSupply,
  ]);

  return (
    <div className='bg-dark-300 w-full p-3 rounded-lg'>
      <div className='w-full flex justify-between items-center gap-4 mb-2'>
        <span className='text-success-500 text-sm'>Market Cap & Progress</span>
        <span className='text-success-500 text-md font-semibold'>{percentSupply.toFixed(1)} %</span>
      </div>
      <div className='bg-success-100/20 rounded-md h-6 relative'>
        <div className='bg-success-100 h-6 rounded-l-md absolute left-0 top-0 pl-2'
          style={{
            width: `${percentSupply / 100}%`
          }}>
          <span className='text-white'>${marketCap.toLocaleString()}</span>
        </div>
      </div>
    </div>
  )
}
